import create from 'zustand';
import { persist } from 'zustand/middleware';

const useCompanyReportStore = create(
	persist(
		(set) => ({
			companyType: '',
			companyId: '',
			setCompanyType: (value) => set({ companyType: value || '' }),
			setCompanyId: (value) => set({ companyId: value || '' }),
		}),
		{ name: 'company-type-and-id' }
	)
);

export default useCompanyReportStore;
