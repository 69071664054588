/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Table, TableContainer, Tbody, Text, Tfoot, Thead, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoData from '../../../../../components/NoData';
import TableData from '../../../../../components/TableData';
import TableHead from '../../../../../components/TableHead';
import SaleProfessionalApprovalStatus from './SaleProfessionalApprovalStatus';

function SaleProfessionalNewList({ detailId = null, detailItems = [], onCallback = () => null, emptyLabel = null }) {
	const { t } = useTranslation();
	const [list, setList] = useState(detailItems);

	return (
		<>
			{list?.length <= 0 && (
				<Box width="100%">
					<>
						<>{emptyLabel ? <Text color="gray.500">{t(emptyLabel)}</Text> : <NoData />}</>
					</>
				</Box>
			)}
			{list?.length > 0 && (
				<Box width="100%">
					<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
						<Table variant="simple">
							<Thead>
								<Tr>
									<TableHead title="National ID Card Number" />
									<TableHead title="Khmer Name" />
									<TableHead title="Latin Name" />
									<TableHead title="Gender" />
									<TableHead title="Branch" />
									<TableHead title="Status" />
									<TableHead title="Description" isNumeric />
								</Tr>
							</Thead>
							<Tbody>
								{list?.map((item, index) => {
									return (
										<Tr key={index}>
											<TableData pt={3} pb={3} child={<Text fontWeight="semibold">{item?.saleProfessional?.idCardNumber}</Text>} />
											<TableData pt={3} pb={3} title={item?.saleProfessional?.lastName + ' ' + item?.saleProfessional?.firstName} />
											<TableData pt={3} pb={3} title={item?.saleProfessional?.lastNameEn + ' ' + item?.saleProfessional?.firstNameEn} />
											<TableData pt={3} pb={3} title={item?.saleProfessional?.gender} />
											<TableData pt={3} pb={3} title={item?.newBranch?.name} />
											<TableData
												pt={3}
												pb={3}
												child={
													<SaleProfessionalApprovalStatus
														id={item?.id}
														approvalStatus={item?.approvalStatus}
														checkAvailability={true}
														onCallback={(el) => {
															const items = list?.reduce((pre, cur) => {
																let localPre = pre;
																let localCur = cur?.id === el?.id ? el : cur;
																localPre.push(localCur);
																return localPre;
															}, []);
															setList(items);
															onCallback({ detailId, items });
														}}
													/>
												}
											/>
											<TableData
												pt={3}
												pb={3}
												title={item?.approvalStatus === false && item?.rejectionReason}
												titleColor="red.400"
												isNumeric
											/>
										</Tr>
									);
								})}
							</Tbody>
							<Tfoot>
								<Tr>
									<TableHead title="National ID Card Number" />
									<TableHead title="Khmer Name" />
									<TableHead title="Latin Name" />
									<TableHead title="Gender" />
									<TableHead title="Branch" />
									<TableHead title="Status" />
									<TableHead title="Description" isNumeric />
								</Tr>
							</Tfoot>
						</Table>
					</TableContainer>
				</Box>
			)}
		</>
	);
}

export default SaleProfessionalNewList;
