/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Badge, Box, Flex, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import CompanyName from '../../CompanyName';
import SaleStaffModal from '../../../containers/CompanySaleProfessional/component/SaleStaffModal';
import ListLoading from '../../../components/ListLoading';
import LocalizedDate from '../../LocalizedDate';
import c from '../../../constant';

const Q_GET_COMPANY_AGENTS = gql`
	query get($offset: Float, $limit: Float, $companyId: String, $status: String) {
		getCompanySaleProfessionalList(pagerInput: { limit: $limit, offset: $offset }, companyId: $companyId, status: $status) {
			totalRows
			totalPages
			list {
				id
				idCardNumber
				firstName
				lastName
				firstNameEn
				lastNameEn
				gender
				dob
				company {
					id
					name
					nameKhmer
					logo
					type
				}
				status
				intermediarySaleProfessional {
					id
					intermediary {
						id
						name
						nameKhmer
						logo
						type
					}
					branch {
						id
						name
						nameLatin
					}
				}
			}
		}
	}
`;

let limit = 10;
let offset = 0;

function CompanyInsuranceSaleStaff({ id = null, ...props }) {
	const { t } = useTranslation();
	const [loadCompanySaleStaff, { loading, data }] = useLazyQuery(Q_GET_COMPANY_AGENTS);
	const [openId, setOpenId] = useState(null);

	useEffect(getCompanyAgents, [id]);

	function onPageChange(value) {
		offset = value * limit;
		getCompanyAgents();
	}

	function getCompanyAgents() {
		const variables = {
			status: 'approved',
			companyId: id,
			offset,
			limit,
		};
		loadCompanySaleStaff({ variables });
	}

	function getStatus(status) {
		switch (status) {
			case 'active':
				return <Badge colorScheme="green">{t('ACTIVE')}</Badge>;
			case 'terminated':
				return <Badge colorScheme="red">{t('Terminated')}</Badge>;
			default:
				return null;
		}
	}

	return (
		<>
			<ListLoading
				loading={loading}
				loadedList={data?.getCompanySaleProfessionalList?.list}
				totalRows={data?.getCompanySaleProfessionalList?.totalRows}
				totalPages={data?.getCompanySaleProfessionalList?.totalPages}
			>
				{({ loadedList, totalPages, totalRows }) => (
					<Box width="100%" padding="16px">
						<Table variant="simple" size="md">
							<Thead>
								<Tr>
									<Th>{t('ID Card Number')}</Th>
									<Th>{t('Name')}</Th>
									<Th>{t('Gender')}</Th>
									<Th>{t('DOB')}</Th>
									<Th>{t('Branch')}</Th>
									<Th>{t('Intermediary Company Name')}</Th>
									<Th isNumeric>{t('Status')}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{loadedList?.map((item, index) => {
									return (
										<Tr key={index} cursor="pointer" _hover={{ bg: 'gray.100' }} onMouseDown={() => setOpenId(item?.id)}>
											<Td>{item?.idCardNumber}</Td>
											<Td>{item?.lastName + ' ' + item?.firstName}</Td>
											<Td>{t(item?.gender)}</Td>
											<Td>
												<LocalizedDate dateString={item?.dob} dateFormat={c.dateOnlyFormat} />
											</Td>
											<Td>
												{item?.intermediarySaleProfessional?.branch?.name ? (
													<Text>{t(item?.intermediarySaleProfessional?.branch?.name)}</Text>
												) : (
													<Badge>{t('None')}</Badge>
												)}
											</Td>
											<Td>
												{item?.intermediarySaleProfessional?.intermediary ? (
													<CompanyName company={item?.intermediarySaleProfessional?.intermediary} />
												) : (
													<Badge>{t('None')}</Badge>
												)}
											</Td>
											<Td isNumeric>{getStatus(item?.status === 'approved' ? 'active' : item?.status)}</Td>
										</Tr>
									);
								})}
							</Tbody>
							<Tfoot>
								<Tr>
									<Th>{t('ID Card Number')}</Th>
									<Th>{t('Name')}</Th>
									<Th>{t('Gender')}</Th>
									<Th>{t('DOB')}</Th>
									<Th>{t('Branch')}</Th>
									<Th>{t('Intermediary Company Name')}</Th>
									<Th isNumeric>{t('Status')}</Th>
								</Tr>
							</Tfoot>
						</Table>
						{totalPages > 1 && (
							<Flex justifyContent="flex-end" w="100%" mt="4">
								<ReactPaginate
									previousLabel={<ChevronLeftIcon fontSize="20px" />}
									nextLabel={<ChevronRightIcon fontSize="20px" />}
									breakLabel={'...'}
									breakClassName={'break-me'}
									disableInitialCallback={true}
									pageCount={totalPages}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={({ selected }) => onPageChange(selected)}
									containerClassName={'pagination'}
									activeClassName={'active'}
									initialPage={offset / limit}
								/>
							</Flex>
						)}
					</Box>
				)}
			</ListLoading>
			{openId && <SaleStaffModal isOpen={true} onClose={() => setOpenId(null)} id={openId} />}
		</>
	);
}

export default CompanyInsuranceSaleStaff;
