import { useRef } from 'react';
import { Box, Flex, IconButton, Text, Tooltip, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from '@chakra-ui/icons';
import { chartBackgroundColor } from '../../Miscellaneous/plugins-chart';
import { formatNumber } from '../../../../utils/format';
import Chart from 'react-chartjs-2';
import Card from '../../../Card/Card';
import DataLoading from '../../../DataLoading';

function Graph({ loading = false, title = '', dataset = null, fileName = 'graph' }) {
	const chartRef = useRef(null);
	const { t } = useTranslation();

	return (
		<Box w={['0%', '50%', '100%']}>
			<Card>
				<Flex justifyContent="center" alignItems="center" flexDirection="column" p={4}>
					<Text fontSize="lg" fontWeight="bold" color="gray.600" textAlign="center" px={4}>
						{t(title)}
					</Text>
				</Flex>
				<DataLoading loading={loading} loadedData={dataset}>
					{({ loadedData }) => (
						<>
							<Box width="100%" p={4} pt={0}>
								<Center>
									<Chart
										ref={chartRef}
										type="bar"
										width="236px"
										height="280px"
										plugins={[chartBackgroundColor]}
										options={{
											barPercentage: 1,
											maintainAspectRatio: false,
											layout: {
												padding: { top: 32, right: 16, bottom: 16, left: 4 },
											},
											plugins: {
												legend: {
													display: true,
													labels: {
														font: { family: 'Rajdhani, Hanuman, sans-serif, Moul', size: 12 },
														generateLabels: (chart) =>
															chart.data.datasets.map((el, i) => {
																const meta = chart.getDatasetMeta(i);
																const style = meta.controller.getStyle(i);
																return {
																	text: t(el.label),
																	fillStyle: style.backgroundColor,
																	strokeStyle: style.borderColor,
																	hidden: !chart.getDataVisibility(i),
																	index: i,
																};
															}),
														usePointStyle: true,
														pointStyle: 'rect',
													},
													position: 'bottom',
												},
												tooltip: {
													callbacks: {
														label: ({ dataset, formattedValue }) => `${t(dataset?.label)}: $${formattedValue}`,
													},
												},
											},
											scales: {
												y: {
													ticks: {
														callback: (value) => `$${formatNumber(value)}`,
													},
												},
											},
										}}
										data={loadedData}
									/>
								</Center>
							</Box>
							<Flex className="hidden-print" justifyContent="flex-end" alignItems="center" p={4}>
								<Tooltip label={t('Download Image')}>
									<IconButton
										size="sm"
										icon={<DownloadIcon w={3} h={3} />}
										onClick={() => {
											const link = document.createElement('a');
											link.download = fileName;
											link.href = chartRef?.current?.toBase64Image('image/jpeg', 1);
											link.click();
										}}
									/>
								</Tooltip>
							</Flex>
						</>
					)}
				</DataLoading>
			</Card>
		</Box>
	);
}

export default Graph;
