function toFixed(x) {
	if (Math.abs(x) < 1.0) {
		let e = parseInt(x.toString().split('e-')[1]);
		if (e) {
			x *= Math.pow(10, e - 1);
			x = '0.' + new Array(e).join('0') + x.toString().substring(2);
		}
	} else {
		let e = parseInt(x.toString().split('+')[1]);
		if (e > 20) {
			e -= 20;
			x /= Math.pow(10, e);
			x += new Array(e + 1).join('0');
		}
	}
	return x;
}

function toFixedTrunc(x, n) {
	const fixedX = toFixed(x);
	const v = (typeof fixedX === 'string' ? fixedX : fixedX.toString()).split('.');
	if (n <= 0) return v[0];
	let f = v[1] || '';
	if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
	while (f.length < n) f += '0';
	return `${v[0]}.${f}`;
}

export { toFixedTrunc };
