/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FaBuilding } from 'react-icons/fa';
import { Button, Flex, Select } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useCompanyReportStore from '../../../store/company-report-persistent-store';
import useStore from '../../../store';

const Q_COMPANIES = gql`
	query get($pagerInput: PagerInput!, $companyName: String, $companyTypes: [InsuranceInstitutionsType!]) {
		getInsuranceCompanies(pagerInput: $pagerInput, companyName: $companyName, companyTypes: $companyTypes) {
			companies {
				id
				name
				nameKhmer
				logo
				url
				type
			}
			totalPages
			totalRows
		}
	}
`;

function CompanyReportButton() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { push } = useHistory();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const { currentLang: lang } = useStore((state) => state?.currentLang);
	const { companyType, companyId, setCompanyType, setCompanyId } = useCompanyReportStore();
	const [companies, setCompanies] = useState([]);
	const [fetchData, { data }] = useLazyQuery(Q_COMPANIES);

	const configCompanyTypes = useMemo(() => {
		const roles = user?.role.split(',') || [];
		if (roles?.includes('mdd')) {
			let permissions = [];
			if (user?.mdd?.includes('mddgmr')) {
				permissions = [...permissions, 'GENERAL_INSURANCE'];
			}
			if (user?.mdd?.includes('mddlmr')) {
				permissions = [...permissions, 'LIFE_INSURANCE'];
			}
			if (user?.mdd?.includes('mddmmr')) {
				permissions = [...permissions, 'MICRO_INSURANCE'];
			}
			return permissions;
		} else {
			return ['GENERAL_INSURANCE', 'LIFE_INSURANCE', 'MICRO_INSURANCE'];
		}
	}, [user]);

	const routes = useMemo(() => ['/company-submission-report', '/early-warning-system', '/market-development-data'], []);

	const checkRouteCallback = useCallback(() => routes.find((value) => new RegExp(value, 'i')?.test(pathname)), [pathname]);

	useEffect(loadCompanies, [companyType]);

	useEffect(sortCompanies, [data?.getInsuranceCompanies]);

	function loadCompanies() {
		const pagerInput = { limit: 300, offset: 0 };
		const companyTypes = companyType ? [companyType] : configCompanyTypes;
		const variables = { pagerInput, companyTypes };
		fetchData({ variables });
	}

	function sortCompanies() {
		setCompanies(data?.getInsuranceCompanies?.companies?.sort((a, b) => (a?.type < b?.type ? -1 : a?.type > b?.type ? 1 : 0)));
	}

	return (
		<>
			{checkRouteCallback() && (
				<Flex transition="all ease-in 0.1s" position="absolute" top="116px" width="300px" _hover={{ ml: '0px !important' }} ml="-272px" zIndex={1000}>
					<Flex width="100%" direction="column" backgroundColor="white" p={4}>
						<Select
							mb={2}
							size="md"
							color="black"
							value={companyType}
							variant="filled"
							cursor="pointer"
							placeholder={t('Company Type')}
							onChange={(e) => {
								setCompanyType(e?.target?.value);
								setCompanyId(null);
								const params = Object.fromEntries(urlParams.entries());
								const objects = {};
								Object.keys(params).forEach((key) => {
									if (key !== 'companyType' && key !== 'companyId') {
										objects[key] = params[key];
									}
								});
								if (e?.target?.value) {
									objects['companyType'] = e?.target?.value;
								}
								const searchParams = new URLSearchParams({ ...objects });
								push(pathname + '?' + searchParams?.toString());
							}}
						>
							{configCompanyTypes?.map((configCompanyType, i) => (
								<option key={i} value={configCompanyType}>
									{t(configCompanyType)}
								</option>
							))}
						</Select>
						<Select
							mb={4}
							size="md"
							color="black"
							value={companyId}
							variant="filled"
							cursor="pointer"
							placeholder={t('Company')}
							onChange={(e) => {
								setCompanyId(e?.target?.value);
								const params = Object.fromEntries(urlParams.entries());
								const objects = {};
								Object.keys(params).forEach((key) => {
									if (key !== 'companyId') {
										objects[key] = params[key];
									}
								});
								if (e?.target?.value) {
									objects['companyId'] = e?.target?.value;
								}
								const searchParams = new URLSearchParams({ ...objects });
								push(pathname + '?' + searchParams?.toString());
							}}
						>
							{companies?.map((company, i) => (
								<option key={i} value={company?.id}>
									{lang === 'kh' ? company?.nameKhmer : company?.name}
								</option>
							))}
						</Select>
						<Flex justifyContent="flex-end" alignItems="center">
							<Button
								size="md"
								isDisabled={!companyType && !companyId}
								colorScheme="blue"
								variant="solid"
								onMouseDown={() => {
									setCompanyType(null);
									setCompanyId(null);
									const params = Object.fromEntries(urlParams.entries());
									const objects = {};
									Object.keys(params).forEach((key) => {
										if (key !== 'companyType' && key !== 'companyId') {
											objects[key] = params[key];
										}
									});
									const searchParams = new URLSearchParams({ ...objects });
									push(pathname + '?' + searchParams?.toString());
								}}
							>
								{t('Clear')}
							</Button>
						</Flex>
					</Flex>
					<Flex justifyContent="center" alignItems="center" width="32px" height="32px" backgroundColor="white">
						<FaBuilding w="24px" h="24px" color={companyType || companyId ? '#3182CE' : '#000000'} />
					</Flex>
				</Flex>
			)}
		</>
	);
}

export default memo(CompanyReportButton);
