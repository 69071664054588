/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, forwardRef } from 'react';
import { Box, Flex, useToast, Text, Button, Tabs, TabList, TabPanel, TabPanels, Tab, useDisclosure, Divider, Badge, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { CompanyBadge } from '../../../Other';
import ExcelGeneral from '../Excel/ExcelGeneral';
import SpreadSheetTable from './table';
// mtpl import SpreadSheetTableMtpl from '../../../../containers/MarketDevelopment/Components/mtpl-table';
// natcat import SpreadSheetTableNatCat from '../../../../containers/MarketDevelopment/Components/nat-cat-table';
import SpreadSheetKeyValuePair from '../../Miscellaneous/spreadsheet-key-value-pair';
import UserDislayNameById from '../../../../components/UserDislayNameById';
import EditMonthlyReportModal from '../../../Modal/EditMonthlyReportModal';
import ContributionSale from '../../Miscellaneous/contribution-sale';
import Loading from '../../../Loading';
import CompanyName from '../../../CompanyName';
import LabelDate from '../../../LabelDate';
import c from '../../../../constant';
import useStore from '../../../../store';

const Q_DETAIL = gql`
	query get($id: String!) {
		getMonthlySaleReportGeneralById(id: $id) {
			id
			reportType
			month
			year
			createdAt
			updatedAt
			company {
				id
				name
				nameKhmer
				logo
				type
			}
			isEditing
			editingUpdatedAt
			editingUpdatedBy
			editingDueDate
			editingDescription
			penalties {
				id
				active
			}
			contribution {
				id
				rate
				grossPremium
				attachmentFile
			}
			saleItems {
				id
				saleReportType
				saleProducts {
					productLine {
						id
						nameKh
						nameEn
						type
					}
					productType {
						id
						nameKh
						nameEn
					}
					businessType
					policyType
					productValues {
						id
						saleProductId
						value
					}
				}
			}
			mtplItems {
				id
				reportId
				template {
					id
					titleKhmer
					titleEnglish
					code
				}
				labels {
					id
					templateLabel {
						id
						titleKhmer
						titleEnglish
						code
					}
					values {
						id
						value
						province {
							id
							name
							latinName
							code
						}
					}
				}
			}
		}
	}
`;

// mtpl const Q_TEMPLATES = gql`
// 	query get($code: String!) {
// 		getTemplateByCode(code: $code) {
// 			id
// 			titleKhmer
// 			titleEnglish
// 			code
// 			order
// 			labels {
// 				id
// 				code
// 				titleKhmer
// 				titleEnglish
// 				level
// 				order
// 				readOnly
// 			}
// 			provinces {
// 				id
// 				code
// 				name
// 				latinName
// 			}
// 		}
// 	}
// `;

// natcat
// const Q_NATCAT = gql`
// 	query get($id: String!) {
// 		getMonthlySaleNatCatReportByReportId(id: $id) {
// 			id
// 			reportId
// 			itemProvinces {
// 				id
// 				itemId
// 				province {
// 					id
// 					name
// 					latinName
// 					code
// 				}
// 				values {
// 					id
// 					itemProvinceId
// 					valueType
// 					subValueType
// 					value
// 				}
// 			}
// 		}
// 	}
// `;

const Page = forwardRef(({ id = null }, ref) => {
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const [canAllowEditing, setCanAllowEditing] = useState(false);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [createdAt, setCreatedAt] = useState(null);
	const [updatedAt, setUpdatedAt] = useState(null);
	const [isPenalized, setPenalized] = useState(false);
	const [company, setCompany] = useState(null);
	const [saleItems, setSaleItems] = useState(null);
	// mtpl const [object, setObject] = useState(null);
	// natcat const [objectNatCat, setObjectNatCat] = useState(null);
	const [getDetail, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(Q_DETAIL);
	// natcat const [getNatCat, { data: dataNatCat }] = useLazyQuery(Q_NATCAT);
	// mtpl const [getTemplates, { data: dataTemplate, error: errorTemplate }] = useLazyQuery(Q_TEMPLATES);

	useEffect(loadDetail, [id]);

	// natcat useEffect(loadNatCat, [id]);

	// mtpl useEffect(loadTemplates, [dataGet]);

	useEffect(() => {
		if (dataGet?.getMonthlySaleReportGeneralById) {
			setMonth(parseInt(dataGet?.getMonthlySaleReportGeneralById?.month) + 1);
			setYear(parseInt(dataGet?.getMonthlySaleReportGeneralById?.year));
			setCreatedAt(dataGet?.getMonthlySaleReportGeneralById?.createdAt);
			setUpdatedAt(dataGet?.getMonthlySaleReportGeneralById?.updatedAt);
			setPenalized(dataGet?.getMonthlySaleReportGeneralById?.penalties?.length > 0);
			setCompany(dataGet?.getMonthlySaleReportGeneralById?.company);
			setSaleItems(dataGet?.getMonthlySaleReportGeneralById?.saleItems);
		}
	}, [dataGet]);

	// natcat
	// useEffect(() => {
	// 	if (dataNatCat?.getMonthlySaleNatCatReportByReportId) {
	// 		const itemProvinces = dataNatCat?.getMonthlySaleNatCatReportByReportId?.itemProvinces;
	// 		const provinces = itemProvinces?.map((itemProvince) => itemProvince?.province);
	// 		const cells = itemProvinces?.map((itemProvince) => itemProvince?.values?.map((value) => ({ ...value, readOnly: true })));
	// 		setObjectNatCat({ provinces, cells });
	// 	}
	// }, [dataNatCat]);

	// mtpl
	// useEffect(() => {
	// 	if (dataTemplate?.getTemplateByCode) {
	// 		const object = dataTemplate?.getTemplateByCode;
	// 		const labels = object?.labels;
	// 		const provinces = object?.provinces?.filter((el) => el?.code !== '9999').sort((a, b) => a?.code < b?.code);
	// 		setObject({
	// 			...object,
	// 			cells: labels?.map((label, i) => {
	// 				if (label?.readOnly) {
	// 					return provinces?.map((el) => ({ value: '', readOnly: true }));
	// 				} else {
	// 					const localTemplate = dataGet?.getMonthlySaleReportGeneralById?.mtplItems?.find((el) => el?.template?.code === c.mtplGSale);
	// 					const localLabels = localTemplate?.labels;
	// 					const localLabel = localLabels?.find((el) => el?.templateLabel?.code === label?.code);
	// 					const localValues = localLabel?.values?.sort((a, b) => a?.province?.code < b?.province?.code);
	// 					return localValues?.map((el) => ({ value: el?.value, readOnly: true }));
	// 				}
	// 			}),
	// 			provinces,
	// 		});
	// 	}
	// }, [dataTemplate]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	// mtpl
	// useEffect(() => {
	// 	if (errorTemplate) {
	// 		if (errorTemplate?.graphQLErrors?.length > 0) {
	// 			errorTemplate?.graphQLErrors.map(({ message }, i) =>
	// 				toast({
	// 					title: t(message),
	// 					status: 'error',
	// 					isClosable: true,
	// 					position: 'bottom-right',
	// 				})
	// 			);
	// 		} else {
	// 			toast({
	// 				title: t(errorTemplate?.message ? errorTemplate?.message : 'something went wrong'),
	// 				status: 'error',
	// 				isClosable: true,
	// 				position: 'bottom-right',
	// 			});
	// 		}
	// 	}
	// }, [errorTemplate]);

	useEffect(() => {
		const roles = user?.role.split(',') || [];
		const status = (roles?.includes('mdd') && user?.mdd?.includes('mddcae')) || roles?.includes('admin');
		setCanAllowEditing(status);
	}, [user]);

	function loadDetail() {
		id && getDetail({ variables: { id } });
	}

	// natcat
	// function loadNatCat() {
	// 	id && getNatCat({ variables: { id } });
	// }

	// mtpl
	// function loadTemplates() {
	// 	dataGet?.getMonthlySaleReportGeneralById && getTemplates({ variables: { code: c.mtplGSale } });
	// }

	return (
		<>
			{loadingGet ? (
				<>
					<Loading />
				</>
			) : (
				<>
					{month && year && createdAt && company && saleItems && (
						<>
							<Flex pl={4} pr={4} pt={0}>
								<SimpleGrid columns={[1, null, 2]} w="100%" spacingX={4} spacingY={4}>
									<Flex flex={1} direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
										<>
											{isPenalized && (
												<Box>
													<Badge colorScheme="red" variant="solid">
														{t('Penalized')}
													</Badge>
												</Box>
											)}
										</>
										<SpreadSheetKeyValuePair label="Company" value={<CompanyName company={company} />} />
										<SpreadSheetKeyValuePair label="Type" value={<CompanyBadge type={company?.type} />} />
										<SpreadSheetKeyValuePair label="Monthly" value={<LabelDate input={month} inputFormat="M" outputFormat="MMMM" />} />
										<SpreadSheetKeyValuePair label="Year" value={<LabelDate input={year} inputFormat="Y" outputFormat="YYYY" />} />
										<SpreadSheetKeyValuePair label="Submitted At" value={<LabelDate input={createdAt} outputFormat={c.dateTimeFormat} />} />
										<>
											{updatedAt && (
												<>
													<SpreadSheetKeyValuePair
														label="Updated at"
														value={<LabelDate input={updatedAt} outputFormat={c.dateTimeFormat} />}
													/>
												</>
											)}
										</>
										<>
											{dataGet?.getMonthlySaleReportGeneralById?.isEditing && (
												<>
													<Divider variant="dashed" mt={2} mb={2} />
													<SpreadSheetKeyValuePair
														label="Allow Editing"
														value={
															<LabelDate
																input={dataGet?.getMonthlySaleReportGeneralById?.editingUpdatedAt}
																outputFormat={c.dateTimeFormat}
															/>
														}
													/>
													<SpreadSheetKeyValuePair
														label="By"
														value={<UserDislayNameById id={dataGet?.getMonthlySaleReportGeneralById?.editingUpdatedBy} />}
													/>
													<SpreadSheetKeyValuePair
														label="Due Date"
														value={
															<LabelDate
																input={dataGet?.getMonthlySaleReportGeneralById?.editingDueDate}
																outputFormat={c.dateTimeFormat}
															/>
														}
													/>
													<SpreadSheetKeyValuePair
														label="Description"
														value={dataGet?.getMonthlySaleReportGeneralById?.editingDescription}
													/>
													{canAllowEditing && (
														<>
															<Box mt={2}>
																<Button
																	onMouseDown={onOpen}
																	isLoading={false}
																	isDisabled={false}
																	onClick={() => null}
																	leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
																	colorScheme="red"
																	variant="solid"
																	size="sm"
																	borderRadius={c.borderRadius}
																	border="1px solid #bdc3c7"
																	mr={2}
																>
																	{t('Stop Allowing Editing')}
																</Button>
															</Box>
														</>
													)}
													<Divider variant="dashed" mt={2} mb={2} />
												</>
											)}
										</>
										<Flex mt={2}>
											<>
												{!dataGet?.getMonthlySaleReportGeneralById?.isEditing && canAllowEditing && (
													<Button
														onMouseDown={onOpen}
														isLoading={false}
														isDisabled={false}
														onClick={() => null}
														leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
														mr={2}
													>
														{t('Allow Editing')}
													</Button>
												)}
											</>
											<>
												<ExcelGeneral month={month} year={year} numColumns={16} saleItems={saleItems} />
											</>
										</Flex>
									</Flex>
									<Flex>
										<ContributionSale contribution={dataGet?.getMonthlySaleReportGeneralById?.contribution} />
									</Flex>
								</SimpleGrid>
							</Flex>
							<Box pl={4} pr={4} pb={4} pt={4} overflow="clip">
								<Tabs>
									<TabList borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.200">
										{saleItems?.map((el) => {
											return (
												<Tab key={el?.id}>
													<Text>{t(el?.saleReportType)}</Text>
												</Tab>
											);
										})}
										{/* mtpl {object && (
											<Tab>
												<Text>{t('MTPL')}</Text>
											</Tab>
										)} */}
										{/* natcat {objectNatCat && (
											<Tab>
												<Text>{t('Natural Catastrophe')}</Text>
											</Tab>
										)} */}
									</TabList>
									<TabPanels>
										{saleItems?.map((el) => {
											return (
												<TabPanel key={el?.id} pl={0} pr={0} pb={0}>
													<SpreadSheetTable saleProducts={el?.saleProducts} />
												</TabPanel>
											);
										})}
										{/* mtpl {object && (
											<TabPanel pl={0} pr={0} pb={0}>
												<SpreadSheetTableMtpl
													readOnlys={[0, 1, 6, 11, 16]}
													labels={object?.labels}
													provinces={object?.provinces}
													cells={object?.cells}
													onChange={(cells) =>
														setObject({
															...object,
															cells: object?.cells?.reduce((pre, cur, i) => {
																let localPre = pre;
																localPre.push(cur?.map((el) => ({ ...el, readOnly: true })));
																return localPre;
															}, []),
														})
													}
												/>
											</TabPanel>
										)} */}
										{/* natcat {objectNatCat && (
											<TabPanel pl={0} pr={0} pb={0}>
												<SpreadSheetTableNatCat
													provinces={objectNatCat?.provinces}
													cells={objectNatCat?.cells}
													onChange={(cells) =>
														setObjectNatCat({
															...objectNatCat,
															cells: objectNatCat?.cells?.reduce((pre, cur, i) => {
																let localPre = pre;
																localPre.push(cur?.map((el) => ({ ...el, readOnly: true })));
																return localPre;
															}, []),
														})
													}
												/>
											</TabPanel>
										)} */}
									</TabPanels>
								</Tabs>
							</Box>
						</>
					)}
				</>
			)}
			{isOpen && (
				<EditMonthlyReportModal
					id={id}
					type="Sale"
					isEditable={dataGet?.getMonthlySaleReportGeneralById?.isEditing}
					editableDueDate={dataGet?.getMonthlySaleReportGeneralById?.editingDueDate}
					editableDescription={dataGet?.getMonthlySaleReportGeneralById?.editingDescription}
					onClose={onClose}
					onCallback={loadDetail}
				/>
			)}
		</>
	);
});

export default Page;
