import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import c from '../../constant';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import FileFormField from '../form/FileFormField';
import moment from 'moment';
import { utils } from '@amir04lm26/react-modern-calendar-date-picker';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import RadioFormField from '../../components/form/RadioFormField';
import DateInputOrSelectFormField from '../../components/form/DateInputOrSelectFormField';
import useMoment from '../../hooks/useMoment';

const MUTATION = gql`
	mutation op($id: String!, $endAt: String!, $description: String, $type: String!, $endFile: Upload, $endFileSize: Float) {
		deactivateAgentCompany(id: $id, endAt: $endAt, description: $description, type: $type, endFile: $endFile, endFileSize: $endFileSize)
	}
`;

const complaintOnInsuranceSchema = Yup.object().shape({
	deactivateDescription: Yup.string(),
	endAt: Yup.mixed()
		.test('empty', 'required', (value) => {
			if (value) {
				return true;
			} else {
				return false;
			}
		})
		.test('format', 'invalid date format', (value) => {
			const regex = new RegExp(
				'^(?:0?[1-9]|[12][0-9]|3[01])(?:-(?:(?:0?[1-9]|1[012])(?:-[0-9]{4})))$|^(?:0?[1-9]|[12][0-9]|3[01])(?:/(?:(?:0?[1-9]|1[012])(?:/[0-9]{4})))$'
			);
			const valid = regex.test(value);
			return valid;
		})
		.test('valid', 'invalid date', (value) => {
			moment.locale(c.localeEn);
			const valid = moment(value, c.dateFormats).isValid();
			return valid;
		})
		.test('range', 'out of allowed range', (value) => {
			moment.locale('en-gb');
			const { isBeforeDate } = utils();
			const day = parseInt(moment(value, c.dateFormats).format('DD'));
			const month = parseInt(moment(value, c.dateFormats).format('MM'));
			const year = parseInt(moment(value, c.dateFormats).format('YYYY'));
			if (day && month && year) {
				if (isBeforeDate(c.minCalendar, { day, month, year }) && isBeforeDate({ day, month, year }, c.maxCalendar)) {
					return true;
				}
			}
			return false;
		}),
	type: Yup.string(),
	endFile: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function DeactivateAgentCompany({ isOpen = false, onClose = () => null, callback = () => null, id }) {
	const toast = useToast();
	const { t } = useTranslation();
	const { toDate, toDateString, dateNow } = useMoment();
	const [selectedType, setSelectedType] = useState(null);
	const [deactivateAgentCompanyMutation, { loading, error, data }] = useMutation(MUTATION);

	useEffect(() => {
		if (data) {
			if (data?.deactivateAgentCompany) {
				toast({
					title: t('Agent company deactivated successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback('Agent company deactivate successfully');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
				}}
				size="md"
				scrollBehavior="outside"
				isCentered
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Deactivate Agent Company')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								deactivateDescription: '',
								publicService: '',
								endAt: null,
								endFile: null,
								type: '',
							}}
							validationSchema={complaintOnInsuranceSchema}
							onSubmit={(values) => {
								console.log('values:', values);
								deactivateAgentCompanyMutation({
									variables: {
										id,
										endAt: toDateString(toDate(values.endAt, c.dateFormats), c.excelDateOnlyFormat, c.localeEn),
										type: values.type,
										description: values.deactivateDescription,
										endFile: values.endFile,
										endFileSize: values?.endFile?.size,
									},
								});
							}}
						>
							{(props) => (
								<Box>
									<Form>
										<RadioFormField label="Type" name="type" radios={deactivateType} callbackValueChange={(e) => setSelectedType(e)} />

										{selectedType === '1' && <TextAreaFormField label="Description" name="deactivateDescription" />}

										<DateInputOrSelectFormField {...props} name="endAt" label="End At" required />

										<FileFormField {...props} label="Attachment" name="endFile" maxFileSize={c.maxFileSize / 10} />
										<Button
											id="login-btn"
											variant="solid"
											size="md"
											h="42px"
											w="128px"
											type="submit"
											borderRadius={c.borderRadius}
											isLoading={loading}
											float="right"
										>
											{t('Deactivate')}
										</Button>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default DeactivateAgentCompany;

const deactivateType = [
	{ value: '7', label: 'មិនបានបន្តសុពលភាពបណ្ណចុះបញ្ជី' },
	{ value: '6', label: 'បោះបង់ការចុះកិច្ចសន្យា' },
	{ value: '5', label: 'បញ្ចប់កិច្ចសន្យា' },
	{ value: '4', label: 'ផ្លាស់ប្តូរសាខា' },
	{ value: '3', label: 'លាឈប់' },
	{ value: '2', label: 'ត្រូវបានបញ្ឈប់' },
	{ value: '1', label: 'ផ្សេងៗ' },
];
