import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	useDisclosure,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import { mappingKhmerNumber } from '../../../utils/market-development';
import SelectValueFormField from '../../form/SelectValueFormField';
import useStore from '../../../store';
import c from '../../../constant';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/km';
import LabelDate from '../../LabelDate';

const validationSchema = Yup.object().shape({
	month: Yup.string().nullable(),
	year: Yup.string().nullable(),
});

export default function MonthlySummarizedFilterSubmit({ month = null, year = null, onCallback = () => null }) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Tooltip
				label={
					(month || year) && (
						<Box>
							{month && (
								<Flex justifyContent="flex-start" alignItems="center">
									<Text fontWeight="bold" color="white" mr={1}>
										{t('Month')}
									</Text>
									<LabelDate input={month + 1} inputFormat="M" outputFormat="MMMM" color="white" />
								</Flex>
							)}
							{year && (
								<Flex justifyContent="flex-start" alignItems="center">
									<Text fontWeight="bold" color="white" mr={1}>
										{t('Year')}
									</Text>
									<LabelDate input={year} inputFormat="Y" outputFormat="YYYY" color="white" />
								</Flex>
							)}
						</Box>
					)
				}
			>
				<Button
					isLoading={false}
					isDisabled={false}
					onClick={onOpen}
					leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
					colorScheme={month || year ? 'yellow' : 'gray'}
					variant="solid"
					size="sm"
					borderRadius={c.borderRadius}
					border="1px solid #bdc3c7"
				>
					<Text fontSize="sm" fontWeight="semibold">
						{t('Filter')}
					</Text>
				</Button>
			</Tooltip>
			<Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							validationSchema={validationSchema}
							initialValues={{
								month,
								year,
							}}
							onSubmit={(values) => {
								let url = '';
								let params = {};

								if (values?.month) {
									params['month'] = values?.month;
								}

								if (values?.year) {
									params['year'] = values?.year;
								}

								var queries = Object.keys(params)
									.map((key) => key + '=' + params[key])
									.join('&');

								if (queries) {
									url = '?' + queries;
								}

								onCallback(url);
								onClose();
							}}
						>
							{({ values, setFieldValue, ...props }) => {
								return (
									<Box pb="4">
										<Form>
											<SimpleGrid columns={1} spacing={4}>
												<SelectValueFormField
													{...props}
													placeholder={t('Month')}
													name="month"
													label="Month"
													options={[
														{ label: lang === 'kh' ? 'មករា' : 'January', value: 0 },
														{ label: lang === 'kh' ? 'កុម្ភៈ' : 'February', value: 1 },
														{ label: lang === 'kh' ? 'មិនា' : 'March', value: 2 },
														{ label: lang === 'kh' ? 'មេសា' : 'April', value: 3 },
														{ label: lang === 'kh' ? 'ឧសភា' : 'May', value: 4 },
														{ label: lang === 'kh' ? 'មិថុនា' : 'June', value: 5 },
														{ label: lang === 'kh' ? 'កក្កដា' : 'July', value: 6 },
														{ label: lang === 'kh' ? 'សីហា' : 'August', value: 7 },
														{ label: lang === 'kh' ? 'កញ្ញា' : 'September', value: 8 },
														{ label: lang === 'kh' ? 'តុលា' : 'October', value: 9 },
														{ label: lang === 'kh' ? 'វិច្ឆិកា' : 'November', value: 10 },
														{ label: lang === 'kh' ? 'ធ្នូ' : 'December', value: 11 },
													]}
												/>
												<SelectValueFormField
													{...props}
													placeholder={t('Year')}
													name="year"
													label="Year"
													options={[0].reduce((p, c) => {
														let lp = p;
														let i = 2021;
														while (i <= moment().year()) {
															lp.push({ label: lang === 'kh' ? mappingKhmerNumber(i) : i, value: i });
															i = i + 1;
														}
														return lp?.reverse();
													}, [])}
												/>
												<Flex mt={2} justifyContent="flex-end">
													<Button
														mr={2}
														colorScheme="gray"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														borderRadius={c.borderRadius}
														onMouseDown={() => {
															onCallback('');
															onClose();
														}}
													>
														{t('Clear')}
													</Button>
													<Button
														ml={2}
														colorScheme="blue"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														type="submit"
														borderRadius={c.borderRadius}
													>
														{t('Apply')}
													</Button>
												</Flex>
											</SimpleGrid>
										</Form>
									</Box>
								);
							}}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
