/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from '@apollo/client';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Badge,
	Button,
	CloseButton,
	Flex,
	Textarea,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSaleProfessionalPermissionStore } from '../../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStore';
import c from '../../../../../constant';

const M_STATUS = gql`
	mutation up($id: String!, $approvalStatus: Boolean, $rejectionReason: String, $checkAvailability: Boolean = false) {
		submitIntermediarySaleProfessionalRequestDetailItemApprovalStatus(
			id: $id
			approvalStatus: $approvalStatus
			rejectionReason: $rejectionReason
			checkAvailability: $checkAvailability
		) {
			id
			order
			detailId
			saleProfessional {
				id
				idCardNumber
				firstName
				lastName
				firstNameEn
				lastNameEn
				gender
				dob
				status
				company {
					id
					name
					nameKhmer
					type
					logo
				}
			}
			oldBranch {
				id
				name
				nameLatin
			}
			newBranch {
				id
				name
				nameLatin
			}
			approvalStatus
			rejectionReason
			terminatedAt
			terminatedReason
		}
	}
`;

function SaleProfessionalApprovalStatus({ id = null, approvalStatus = null, checkAvailability = false, onCallback = () => null }) {
	const cancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { isPermitted } = useSaleProfessionalPermissionStore((state) => state?.isPermitted);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [rejectionReason, setRejectionReason] = useState('');
	const [updateStatus, { error, loading, data }] = useMutation(M_STATUS);

	useEffect(() => {
		if (data?.submitIntermediarySaleProfessionalRequestDetailItemApprovalStatus) {
			onCallback(data?.submitIntermediarySaleProfessionalRequestDetailItemApprovalStatus);
			onClose();
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function approve() {
		updateStatus({
			variables: {
				id,
				approvalStatus: true,
				rejectionReason: null,
				checkAvailability,
			},
		});
	}

	function reject() {
		updateStatus({
			variables: {
				id,
				approvalStatus: false,
				rejectionReason: rejectionReason,
				checkAvailability,
			},
		});
	}

	function reset() {
		updateStatus({
			variables: {
				id,
				approvalStatus: null,
				rejectionReason: null,
				checkAvailability,
			},
		});
	}

	return (
		<>
			{approvalStatus === null && (
				<>
					<Flex justifyContent="flex-start" alignItems="center">
						<Tooltip label={t('approve')}>
							<Button
								borderRadius={c.borderRadius}
								size="sm"
								isLoading={loading}
								isDisabled={isPermitted ? false : true}
								colorScheme="green"
								onMouseDown={approve}
								ml={0}
							>
								{t('approve')}
							</Button>
						</Tooltip>
						<Tooltip label={t('reject')}>
							<Button
								borderRadius={c.borderRadius}
								size="sm"
								isLoading={loading}
								isDisabled={isPermitted ? false : true}
								colorScheme="red"
								onMouseDown={onOpen}
								ml={2}
							>
								{t('reject')}
							</Button>
						</Tooltip>
					</Flex>
				</>
			)}
			{approvalStatus === true && (
				<>
					<Flex justifyContent="flex-start" alignItems="center">
						<Tooltip label={t('approved')}>
							<Badge colorScheme="green" variant="solid">
								{t('approved')}
							</Badge>
						</Tooltip>
						<Tooltip label={t('You can change this status')}>
							<CloseButton
								borderRadius={c.borderRadius}
								variant="solid"
								hidden={isPermitted ? false : true}
								onMouseDown={reset}
								size="xs"
								p={1}
								ml={1}
								color="gray.400"
							/>
						</Tooltip>
					</Flex>
				</>
			)}
			{approvalStatus === false && (
				<>
					<Flex justifyContent="flex-start" alignItems="center">
						<Tooltip label={t('rejected')}>
							<Badge colorScheme="red" variant="solid">
								{t('rejected')}
							</Badge>
						</Tooltip>
						<Tooltip label={t('You can change this status')}>
							<CloseButton
								borderRadius={c.borderRadius}
								variant="solid"
								hidden={isPermitted ? false : true}
								onMouseDown={reset}
								size="xs"
								p={1}
								ml={1}
								color="gray.400"
							/>
						</Tooltip>
					</Flex>
				</>
			)}
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Reason of Rejection')}
						</AlertDialogHeader>
						<AlertDialogBody>
							{t('Please input reason of rejection')}
							<Textarea value={rejectionReason} onChange={(e) => setRejectionReason(e.currentTarget.value)} />
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button borderRadius={c.borderRadius} isLoading={loading} colorScheme="gray" ref={cancelRef} onMouseDown={onClose} ml={0}>
								{t('Cancel')}
							</Button>
							<Button
								borderRadius={c.borderRadius}
								isLoading={loading}
								isDisabled={rejectionReason ? false : true}
								colorScheme="red"
								onMouseDown={reject}
								ml={2}
							>
								{t('Reject')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default SaleProfessionalApprovalStatus;
