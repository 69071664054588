/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, forwardRef } from 'react';
import { Box, Flex, useToast, Text, Button, Tabs, TabList, TabPanel, TabPanels, Tab, useDisclosure, Badge, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { CompanyBadge } from '../../../Other';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import ExcelMicro from '../Excel/ExcelMicro';
import SpreadSheetTable from './table';
import SpreadSheetKeyValuePair from '../../Miscellaneous/spreadsheet-key-value-pair';
import UserDislayNameById from '../../../../components/UserDislayNameById';
import EditMonthlyReportModal from '../../../Modal/EditMonthlyReportModal';
import Loading from '../../../Loading';
import CompanyName from '../../../CompanyName';
import LabelDate from '../../../LabelDate';
import useStore from '../../../../store';
import c from '../../../../constant';

const Q_DISTRIBUTION_ITEMS = gql`
	query get($id: String!) {
		getMonthlyDistributionReportItems(id: $id) {
			id
			reportType
			month
			year
			createdAt
			updatedAt
			company {
				id
				name
				nameKhmer
				logo
				type
			}
			isEditing
			editingUpdatedAt
			editingUpdatedBy
			editingDueDate
			editingDescription
			penalties {
				id
				active
			}
			distributionItems {
				id
				productLine {
					id
					nameKh
					nameEn
					type
				}
			}
		}
	}
`;

const Page = forwardRef(({ id = null }, ref) => {
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const [canAllowEditing, setCanAllowEditing] = useState(false);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [createdAt, setCreatedAt] = useState(null);
	const [updatedAt, setUpdatedAt] = useState(null);
	const [isPenalized, setPenalized] = useState(false);
	const [company, setCompany] = useState(null);
	const [distributionItems, setDistributionItems] = useState(null);
	const [getDetail, { data, loading, error }] = useLazyQuery(Q_DISTRIBUTION_ITEMS, { fetchPolicy: 'no-cache' });

	useEffect(loadDetail, [id]);

	useEffect(() => {
		if (data) {
			setMonth(parseInt(data?.getMonthlyDistributionReportItems?.month) + 1);
			setYear(parseInt(data?.getMonthlyDistributionReportItems?.year));
			setCreatedAt(data?.getMonthlyDistributionReportItems?.createdAt);
			setUpdatedAt(data?.getMonthlyDistributionReportItems?.updatedAt);
			setPenalized(data?.getMonthlyDistributionReportItems?.penalties?.length > 0);
			setCompany(data?.getMonthlyDistributionReportItems?.company);
			setDistributionItems(data?.getMonthlyDistributionReportItems?.distributionItems);
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		const roles = user?.role.split(',') || [];
		const status = (roles?.includes('mdd') && user?.mdd?.includes('mddcae')) || roles?.includes('admin');
		setCanAllowEditing(status);
	}, [user]);

	function loadDetail() {
		id && getDetail({ variables: { id } });
	}

	return (
		<>
			{loading && <Loading />}
			{!loading && month && year && createdAt && company && distributionItems && (
				<>
					<Flex pl={4} pr={4} w="100%">
						<SimpleGrid columns={[1, data?.getMonthlyDistributionReportItems?.isEditing ? 2 : 1]} w="100%" spacingX={4} spacingY={4}>
							<Flex w="100%" direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
								<>
									{isPenalized && (
										<Box>
											<Badge colorScheme="red" variant="solid">
												{t('Penalized')}
											</Badge>
										</Box>
									)}
								</>
								<SpreadSheetKeyValuePair label="Company" value={<CompanyName company={company} />} />
								<SpreadSheetKeyValuePair label="Type" value={<CompanyBadge type={company?.type} />} />
								<SpreadSheetKeyValuePair label="Monthly" value={<LabelDate input={month} inputFormat="M" outputFormat="MMMM" />} />
								<SpreadSheetKeyValuePair label="Year" value={<LabelDate input={year} inputFormat="Y" outputFormat="YYYY" />} />
								<SpreadSheetKeyValuePair label="Submitted At" value={<LabelDate input={createdAt} outputFormat={c.dateTimeFormat} />} />
								<>
									{updatedAt && (
										<>
											<SpreadSheetKeyValuePair
												label="Updated at"
												value={<LabelDate input={updatedAt} outputFormat={c.dateTimeFormat} />}
											/>
										</>
									)}
								</>
								<Flex mt={2}>
									<>
										{!data?.getMonthlyDistributionReportItems?.isEditing && canAllowEditing && (
											<Button
												onMouseDown={onOpen}
												isLoading={false}
												isDisabled={false}
												onClick={() => null}
												leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
												colorScheme="gray"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
												mr={2}
											>
												{t('Allow Editing')}
											</Button>
										)}
									</>
									<Flex>
										<ExcelMicro id={id} month={month} year={year} numColumns={21} />
									</Flex>
								</Flex>
							</Flex>
							{data?.getMonthlyDistributionReportItems?.isEditing && (
								<Flex w="100%" direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
									<SpreadSheetKeyValuePair
										label="Allow Editing"
										value={<LabelDate input={data?.getMonthlyDistributionReportItems?.editingUpdatedAt} outputFormat={c.dateTimeFormat} />}
									/>
									<SpreadSheetKeyValuePair
										label="By"
										value={<UserDislayNameById id={data?.getMonthlyDistributionReportItems?.editingUpdatedBy} />}
									/>
									<SpreadSheetKeyValuePair
										label="Due Date"
										value={<LabelDate input={data?.getMonthlyDistributionReportItems?.editingDueDate} outputFormat={c.dateTimeFormat} />}
									/>
									<SpreadSheetKeyValuePair label="Description" value={data?.getMonthlyDistributionReportItems?.editingDescription} />
									{canAllowEditing && (
										<>
											<Box mt={2}>
												<Button
													onMouseDown={onOpen}
													isLoading={false}
													isDisabled={false}
													onClick={() => null}
													leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
													colorScheme="red"
													variant="solid"
													size="sm"
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7"
													mr={2}
												>
													{t('Stop Allowing Editing')}
												</Button>
											</Box>
										</>
									)}
								</Flex>
							)}
						</SimpleGrid>
					</Flex>
					<Box pl={4} pr={4} pb={4} pt={4} overflow="clip">
						<Tabs isLazy variant="soft-rounded" colorScheme="green">
							<TabList overflow="scroll">
								{distributionItems?.map((el) => {
									return (
										<Tab key={el?.id}>
											<Text fontSize="small">{el?.productLine?.nameKh}</Text>
										</Tab>
									);
								})}
							</TabList>
							<TabPanels>
								{distributionItems?.map((el) => {
									return (
										<TabPanel key={el?.id} pl={0} pr={0} pb={0}>
											<>
												<SpreadSheetTable distributionItemId={el?.id} />
											</>
										</TabPanel>
									);
								})}
							</TabPanels>
						</Tabs>
					</Box>
				</>
			)}
			{isOpen && (
				<EditMonthlyReportModal
					id={id}
					type="Distribution"
					isEditable={data?.getMonthlyDistributionReportItems?.isEditing}
					editableDueDate={data?.getMonthlyDistributionReportItems?.editingDueDate}
					editableDescription={data?.getMonthlyDistributionReportItems?.editingDescription}
					onClose={onClose}
					onCallback={loadDetail}
				/>
			)}
		</>
	);
});

export default Page;
