/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { formatCurrency, formatNumber } from '../../../../utils/format';
import { toFixedTrunc } from '../../../../utils/fixed-trunc';
import DataLoading from '../../../DataLoading';
import moment from 'moment';

const Q_DATASET = gql`
	query get($year: Float!) {
		getMonthlyGrowthReport(year: $year)
	}
`;

export default function MonthlyGrowthSpreadSheetTable({ year = null }) {
	const { t } = useTranslation();
	const toast = useToast();
	const [titles, setTitles] = useState(null);
	const [localYear, setLocalYear] = useState(null);
	const [getDataset, { loading, data, error }] = useLazyQuery(Q_DATASET, { fetchPolicy: 'no-cache' });

	useEffect(() => {
		setLocalYear(year ? parseInt(year) : moment().year());
	}, [year]);

	useEffect(() => {
		if (data?.getMonthlyGrowthReport) {
			setTitles(data?.getMonthlyGrowthReport?.titles);
		}
	}, [data]);

	const load = useCallback(() => {
		getDataset({ variables: { year: localYear } });
	}, [getDataset, localYear]);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Flex justifyContent="flex-start" alignItems="center" ml={4} mr={4}>
				<Text fontSize={'15px'} fontWeight={'bold'}>
					{`${t('1. Monthly Market Growth')} ${t(localYear)}`}
				</Text>
			</Flex>
			<DataLoading loading={loading} loadedData={data?.getMonthlyGrowthReport}>
				{({ loadedData }) => (
					<Box ml={4} mr={4} mt={0} mb={0} overflow="scroll">
						<table className="spreadsheet_custom_header_report" width={'100%'} style={{ marginTop: '10px' }}>
							<thead style={{ height: '35px' }}>
								<tr>
									<th></th>
									<td>{t('Type of Insurance')}</td>
									{titles?.map((el) => {
										return <td>{t(el?.labelEn)}</td>;
									})}
								</tr>
							</thead>
							<tbody>
								{/* Premium */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Gross Premium')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.grossPremium?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.grossPremium?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.grossPremium?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.grossPremium?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.grossPremium?.Total?.map((el, i) => {
										if (i === loadedData?.grossPremium?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Number of Policies */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Number of Policies')} (#)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.numPolicies?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.numPolicies?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.numPolicies?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.numPolicies?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.numPolicies?.Total?.map((el, i) => {
										if (i === loadedData?.numPolicies?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Sum Insured */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Sum Insured')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.sumInsured?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.sumInsured?.Total?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.sumInsured?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.sumInsured?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.sumInsured?.Total?.map((el, i) => {
										if (i === loadedData?.sumInsured?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Gross Claim incurred */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Gross Claim Incurred')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.claimIncurred?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimIncurred?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.claimIncurred?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimIncurred?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.claimIncurred?.Total?.map((el, i) => {
										if (i === loadedData?.claimIncurred?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Gross Claim Paid */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Gross Claim Paid')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.claimPaid?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimPaid?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.claimPaid?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimPaid?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.claimPaid?.Total?.map((el, i) => {
										if (i === loadedData?.claimPaid?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
							</tbody>
						</table>
					</Box>
				)}
			</DataLoading>
		</>
	);
}
