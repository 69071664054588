import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	useDisclosure,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/km';
import useStore from '../../../../store';
import c from '../../../../constant';
import SelectValueFormField from '../../../form/SelectValueFormField';
import { mappingKhmerNumber } from '../../../../utils/market-development';
import ReactExport from 'react-export-excel';
import { fileName } from '../../Miscellaneous/export';
import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toFixedTrunc } from '../../../../utils/fixed-trunc';

const Q_REPORTS = gql`
	query get($limit: Float!, $offset: Float!, $month: Float, $year: Float, $companyId: String, $companyType: InsuranceInstitutionsType) {
		getMonthlySaleReports(pagerInput: { limit: $limit, offset: $offset }, companyId: $companyId, companyType: $companyType, month: $month, year: $year) {
			list {
				id
				company {
					name
					nameKhmer
					type
				}
				month
				year
				createdAt
				reportType
				contribution {
					rate
					grossPremium
				}
			}
		}
	}
`;

const validationSchema = Yup.object().shape({
	month: Yup.string().required('required'),
	year: Yup.string().required('required'),
});

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExcelInsuranceSales() {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [dataList, setDataList] = useState([]);
	const [showError, setShowError] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [getMonthlySaleReports, { loading, data }] = useLazyQuery(Q_REPORTS);

	useEffect(() => {
		if (data?.getMonthlySaleReports?.list) {
			const formattedData = data?.getMonthlySaleReports?.list?.map((item) => ({
				company: item?.company?.name || 'N/A',
				companyNameKh: item?.company?.nameKhmer || 'N/A',
				type: item?.company?.type || 'N/A',
				montly: item?.month || 'N/A',
				year: item?.year || 'N/A',
				submittedAt: item?.createdAt ? moment(item?.createdAt).format('YYYY-MM-DD') : 'N/A',
				status: item?.reportType || 'N/A',
				grossPremium: item?.contribution?.grossPremium || 'N/A',
				monthlyContribution: toFixedTrunc((item?.contribution?.grossPremium * item?.contribution?.rate) / 100, 3),
			}));
			setDataList(formattedData);
		}
	}, [data]);

	return (
		<>
			<Button
				isLoading={false}
				isDisabled={false}
				onClick={onOpen}
				leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
				variant="solid"
				size="sm"
				borderRadius={c.borderRadius}
				border="1px solid #bdc3c7"
			>
				<Text fontSize="sm" fontWeight="semibold">
					{t('Export')}
				</Text>
			</Button>
			<Modal
				borderRadius={c.borderRadius}
				isOpen={isOpen}
				onClose={onClose}
				closeOnEsc={loading ? false : true}
				closeOnOverlayClick={loading ? false : true}
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Export CSV')}</ModalHeader>
					<ModalCloseButton isDisabled={loading} />
					<ModalBody>
						<Formik
							validationSchema={validationSchema}
							initialValues={{
								month: '',
								year: '',
							}}
							onSubmit={(values) => {
								setSelectedMonth(values.month);
								setSelectedYear(values.year);
								getMonthlySaleReports({
									variables: {
										limit: 3000,
										offset: 0,
										companyId: null,
										companyType: null,
										month: parseInt(values?.month),
										year: parseInt(values?.year),
									},
								});
							}}
						>
							{({ values, setFieldValue, ...props }) => {
								return (
									<Box pb="4">
										<Form>
											<SimpleGrid columns={1} spacing={4}>
												<SelectValueFormField
													{...props}
													required={true}
													placeholder={t('Month')}
													name="month"
													label="Month"
													options={[
														{ label: lang === 'kh' ? 'មករា' : 'January', value: 0 },
														{ label: lang === 'kh' ? 'កុម្ភៈ' : 'February', value: 1 },
														{ label: lang === 'kh' ? 'មិនា' : 'March', value: 2 },
														{ label: lang === 'kh' ? 'មេសា' : 'April', value: 3 },
														{ label: lang === 'kh' ? 'ឧសភា' : 'May', value: 4 },
														{ label: lang === 'kh' ? 'មិថុនា' : 'June', value: 5 },
														{ label: lang === 'kh' ? 'កក្កដា' : 'July', value: 6 },
														{ label: lang === 'kh' ? 'សីហា' : 'August', value: 7 },
														{ label: lang === 'kh' ? 'កញ្ញា' : 'September', value: 8 },
														{ label: lang === 'kh' ? 'តុលា' : 'October', value: 9 },
														{ label: lang === 'kh' ? 'វិច្ឆិកា' : 'November', value: 10 },
														{ label: lang === 'kh' ? 'ធ្នូ' : 'December', value: 11 },
													]}
												/>
												<SelectValueFormField
													{...props}
													placeholder={t('Year')}
													name="year"
													required={true}
													label="Year"
													options={[0].reduce((p, c) => {
														let lp = p;
														let i = 2021;
														while (i <= moment().year()) {
															lp.push({ label: lang === 'kh' ? mappingKhmerNumber(i) : i, value: i });
															i = i + 1;
														}
														return lp?.reverse();
													}, [])}
												/>
												<Flex mt={2} justifyContent="flex-end">
													<Button
														mr={2}
														colorScheme="gray"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														borderRadius={c.borderRadius}
														type="submit"
													>
														{t('Get Data')}
													</Button>
													<ExcelFile
														filename={fileName('Insurance Sales Statement', '', selectedMonth, selectedYear)}
														element={
															<Button
																mr={2}
																colorScheme="gray"
																variant="solid"
																size="md"
																h="42px"
																w="128px"
																borderRadius={c.borderRadius}
																isDisabled={dataList.length === 0}
																onClick={() => {
																	if (dataList.length === 0) {
																		setShowError(true);
																	}
																}}
															>
																{t('Export')}
															</Button>
														}
													>
														<ExcelSheet data={dataList} name="Insurance Sales Report">
															<ExcelColumn label="Company" value="company" />
															<ExcelColumn label="CompanyNameKH" value="companyNameKh" />
															<ExcelColumn label="Type" value="type" />
															<ExcelColumn label="Monthly" value="monthly" />
															<ExcelColumn label="Year" value="year" />
															<ExcelColumn label="Submitted At" value="submittedAt" />
															<ExcelColumn label="Status" value="status" />
															<ExcelColumn label="Gross Premium" value="grossPremium" />
															<ExcelColumn label="Monthly Contribution" value="monthlyContribution" />
														</ExcelSheet>
													</ExcelFile>

													{showError && <Text color="red.500">{t('No data found')}</Text>}
												</Flex>
											</SimpleGrid>
										</Form>
									</Box>
								);
							}}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
