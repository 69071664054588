import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';
import './Styles.css';

const ListLoading = ({ loading = false, loadedList = null, children = null, ...props }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box width="100%" display={loading ? 'block' : 'none'} animation={loading ? 'fade-in 1s ease-in-out' : 'fade-out 1s ease-in-out'}>
				<Loading />
			</Box>
			<Flex
				w="100%"
				display={!loading && loadedList?.length <= 0 ? 'block' : 'none'}
				animation={!loading && loadedList?.length <= 0 ? 'fade-in 1s ease-in-out' : 'fade-out 1s ease-in-out'}
				justifyContent="center"
				alignItems="center"
				pt={24}
				pb={24}
				pl={0}
				pr={0}
			>
				<Text color="gray.400" textAlign="center" mt={4}>
					{t('No Data')}
				</Text>
			</Flex>
			<Box
				display={!loading && loadedList?.length > 0 ? 'block' : 'none'}
				animation={!loading && loadedList?.length > 0 ? 'fade-in 1s ease-in-out' : 'fade-out 1s ease-in-out'}
				width="100%"
			>
				{children({ loadedList: loadedList || [], ...props })}
			</Box>
		</>
	);
};

export default ListLoading;
