import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex, useDisclosure } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import MdCompanyTypeFormField from './Forms/MdCompanyTypeFormField';
import MdCompanyFormField from './Forms/MdCompanyFormField';
import MdDateOptionFormField from './Forms/MdDateOptionFormField';
import moment from 'moment';
import 'moment/locale/en-gb';
import c from '../../../constant';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	companyType: Yup.string().nullable(),
	companyId: Yup.string().nullable(),
	rangeOption: Yup.string().nullable(),
	rangeValue: Yup.mixed().when('rangeOption', {
		is: (rangeOption) => rangeOption === 'select',
		then: Yup.object().test('check', 'Required', (value) => value?.from && value?.to),
		otherwise: Yup.object(),
	}),
});

function FilterDashboard({ companyType = null, companyId = null, rangeOption = null, rangeValue = { from: null, to: null }, onCallback = () => null }) {
	moment.locale('en-gb');
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	function calendarToMoment(value) {
		let from = null;
		let to = null;
		if (value?.from && value?.to) {
			const inputFrom = { day: value?.from?.day, month: value?.from?.month - 1, year: value?.from?.year };
			const inputTo = { day: value?.to?.day, month: value?.to?.month - 1, year: value?.to?.year };
			from = moment(inputFrom).startOf('day').toDate();
			to = moment(inputTo).endOf('day').toDate();
		}
		return { from, to };
	}

	function momentToCalendar(value) {
		let from = null;
		let to = null;
		if (value?.from && value?.to) {
			const dateFrom = moment(value?.from);
			const dateTo = moment(value?.to);
			from = { day: parseInt(dateFrom?.format('DD')), month: parseInt(dateFrom?.format('MM')), year: parseInt(dateFrom?.format('YYYY')) };
			to = { day: parseInt(dateTo?.format('DD')), month: parseInt(dateTo?.format('MM')), year: parseInt(dateTo?.format('YYYY')) };
		}
		return { from, to };
	}

	return (
		<>
			<Button
				className="hidden-print"
				isLoading={false}
				isDisabled={false}
				leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
				size="sm"
				borderRadius={c.borderRadius}
				border="1px solid #bdc3c7"
				width={['100%', '100%', 'auto']}
				colorScheme={companyType || companyId || (rangeValue?.from && rangeValue?.to) ? 'yellow' : 'gray'}
				variant="solid"
				ml={[0, 0, 2]}
				mt={[2, 2, 0]}
				onMouseDown={onOpen}
			>
				{t('Filter')}
			</Button>
			<Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								companyType: companyType || '',
								companyId: companyId || '',
								rangeOption,
								rangeValue: momentToCalendar(rangeValue),
							}}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								let companyType = null;
								if (values?.companyType) {
									companyType = values?.companyType;
								}
								let companyId = null;
								if (values?.companyId) {
									companyId = values?.companyId;
								}
								let rangeOption = null;
								let from = null;
								let to = null;
								if (values?.rangeOption) {
									if (values?.rangeOption === 'q1') {
										from = moment().set('quarter', 1).startOf('quarter').toDate();
										to = moment().set('quarter', 1).endOf('quarter').toDate();
									} else if (values?.rangeOption === 'q2') {
										from = moment().set('quarter', 2).startOf('quarter').toDate();
										to = moment().set('quarter', 2).endOf('quarter').toDate();
									} else if (values?.rangeOption === 'q3') {
										from = moment().set('quarter', 3).startOf('quarter').toDate();
										to = moment().set('quarter', 3).endOf('quarter').toDate();
									} else if (values?.rangeOption === 'q4') {
										from = moment().set('quarter', 4).startOf('quarter').toDate();
										to = moment().set('quarter', 4).endOf('quarter').toDate();
									} else if (values?.rangeOption === 's1') {
										from = moment().set('quarter', 1).startOf('quarter').toDate();
										to = moment().set('quarter', 2).endOf('quarter').toDate();
									} else if (values?.rangeOption === 's2') {
										from = moment().set('quarter', 3).startOf('quarter').toDate();
										to = moment().set('quarter', 4).endOf('quarter').toDate();
									} else if (values?.rangeOption === 'year') {
										from = moment().startOf('year').toDate();
										to = moment().endOf('year').toDate();
									} else if (values?.rangeOption === 'select') {
										const value = calendarToMoment(values?.rangeValue);
										from = value?.from;
										to = value?.to;
									}
									rangeOption = values?.rangeOption;
								}
								onCallback({ companyType, companyId, rangeOption, rangeValue: { from, to } });
								onClose();
							}}
						>
							{(props) => (
								<Box pb="4">
									<Form>
										<MdCompanyTypeFormField
											{...props}
											name="companyType"
											companyFormName="companyId"
											label="Company Type"
											placeholder={t('Company Type')}
										/>
										<MdCompanyFormField {...props} name="companyId" companyTypeFormName="companyType" label="Company" />
										<center>
											<MdDateOptionFormField {...props} label="Date" name="rangeOption" />
										</center>
										<Flex mt="32px" justifyContent="flex-end">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
												color="#3498db"
											>
												{t('Apply')}
											</Button>
											<Button
												size="md"
												h="42px"
												ml={4}
												w="128px"
												onClick={() => {
													onCallback({ companyType: null, companyId: null, rangeOption: null, rangeValue: { from: null, to: null } });
													onClose();
												}}
												variant="ghost"
											>
												{t('Clear')}
											</Button>
										</Flex>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default FilterDashboard;
