import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ViewAgentComment from '../../components/Comment/AgentComment/ViewAgentComment.js';

const AgentComment = ({ id, idCardNumber, onClose = () => null }) => {
	const { t } = useTranslation();

	return (
		<Modal size="3xl" isOpen={true} onClose={() => onClose(false)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Text fontSize="x-large" fontWeight="bold" textAlign="left" w="100%" pt="8px" pb="8px">
						{t('Agent Comments')}
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<ViewAgentComment id={id} idCardNumber={idCardNumber} type={'AGENT_COMMENT'} />
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default AgentComment;
