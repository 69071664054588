/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Container, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import PrintLayout from '../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import Filter from '../../../components/MarketDevelopment/Miscellaneous/summarizedFilter';
import c from '../../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import FinalSpreadSheetTable from '../../../components/MarketDevelopment/MonthlySale/Dashboard/final-spread-sheet-table';
import MonthlyGrowthSpreadSheetTable from '../../../components/MarketDevelopment/MonthlySale/Dashboard/monthly-growth-sheet-table';
import SummarizedSpreadSheetTable from '../../../components/MarketDevelopment/MonthlySale/Dashboard/summarized-sheet-table';

function SummarizedReport() {
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const month = urlParams.get('month') ? urlParams.get('month') : null;
	const year = urlParams.get('year') ? urlParams.get('year') : null;
	const { t } = useTranslation();

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Company Report',
								path: '/company-submission-report',
							},
							{
								name: 'Monthly Summarized Report',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t('Company Report')}
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Text fontSize="x-large" fontWeight="bold">
									{t('Monthly Summarized Report')}
								</Text>
								<Flex>
									<Filter
										month={month}
										year={year}
										onCallback={(url) => history.push(`/market-development-data/monthly-summarized-report${url}`)}
									/>
								</Flex>
							</Flex>
							<Box pl={4} pr={4} pb={4} pt={4} overflow="clip">
								<Tabs>
									<TabList borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.200">
										<Tab>
											<Text>{t('Final')}</Text>
										</Tab>
										<Tab>
											<Text>{t('Monthly Growth')}</Text>
										</Tab>
										<Tab>
											<Text>{t('General')}</Text>
										</Tab>
										<Tab>
											<Text>{t('Life')}</Text>
										</Tab>
										<Tab>
											<Text>{t('Micro')}</Text>
										</Tab>
										<Tab>
											<Text>{t('GI include MI')}</Text>
										</Tab>
										<Tab>
											<Text>{t('LI include MI')}</Text>
										</Tab>
									</TabList>
									<Box pl={0} pr={0} pb={4} pt={4} overflow="clip">
										<TabPanels>
											<TabPanel pl={0} pr={0} pb={0}>
												<FinalSpreadSheetTable month={month} year={year} />
											</TabPanel>
											<TabPanel pl={0} pr={0} pb={0}>
												<MonthlyGrowthSpreadSheetTable month={month} year={year} />
											</TabPanel>
											<TabPanel pl={0} pr={0} pb={0}>
												<SummarizedSpreadSheetTable type={'General'} month={month} year={year} />
											</TabPanel>
											<TabPanel pl={0} pr={0} pb={0}>
												<SummarizedSpreadSheetTable type={'Life'} month={month} year={year} />
											</TabPanel>
											<TabPanel pl={0} pr={0} pb={0}>
												<SummarizedSpreadSheetTable type={'Micro'} month={month} year={year} />
											</TabPanel>
											<TabPanel pl={0} pr={0} pb={0}>
												<SummarizedSpreadSheetTable type={'GeneralIncludeMicro'} month={month} year={year} />
											</TabPanel>
											<TabPanel pl={0} pr={0} pb={0}>
												<SummarizedSpreadSheetTable type={'LifeIncludeMicro'} month={month} year={year} />
											</TabPanel>
										</TabPanels>
									</Box>
								</Tabs>
							</Box>
						</Box>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default SummarizedReport;
