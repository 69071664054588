/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { formatCurrency, formatNumber } from '../../../../utils/format';
import { toFixedTrunc } from '../../../../utils/fixed-trunc';
import GiLiFinalGrowthByProductLine from './FinalGrowthByProductLineGraph';
import GiGrossPremiumByProductLine from './GrossPremiumByProductLineGraph';
import DataLoading from '../../../DataLoading';
import moment from 'moment';

const Q_DATASET = gql`
	query get($year: Float!, $month: Float!) {
		getMonthlyFinalReport(year: $year, month: $month)
	}
`;

export default function FinalSpreadSheetTable({ type = null, year = null, month = null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [titles, setTitles] = useState(null);
	const [genProducts, setGenProducts] = useState(null);
	const [lifeProducts, setLifeProducts] = useState(null);
	const [localYear, setLocalYear] = useState(null);
	const [localMonth, setLocalMonth] = useState(null);
	const [getDataset, { loading, data, error }] = useLazyQuery(Q_DATASET, { fetchPolicy: 'no-cache' });

	useEffect(() => {
		setLocalYear(year ? parseInt(year) : moment().year());
		setLocalMonth(month ? parseInt(month) : moment().month());
	}, [month, year]);

	useEffect(() => {
		if (data?.getMonthlyFinalReport) {
			setTitles(data?.getMonthlyFinalReport?.overall?.titles);
			setGenProducts(data?.getMonthlyFinalReport?.general?.products);
			setLifeProducts(data?.getMonthlyFinalReport?.life?.products);
		}
	}, [data]);

	const load = useCallback(() => {
		getDataset({ variables: { year: localYear, month: localMonth } });
	}, [getDataset, localYear, localMonth]);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Flex justifyContent="flex-start" alignItems="center" ml={4} mr={4}>
				<Text fontSize={'15px'} fontWeight={'bold'}>
					{`${t('1. Insurance market data for')} ${t(moment(localMonth + 1, 'MM').format('MMMM'))} ${t(localYear)}`}
				</Text>
			</Flex>
			<DataLoading loading={loading} loadedData={data?.getMonthlyFinalReport?.overall}>
				{({ loadedData }) => (
					<Box ml={4} mr={4} mt={0} mb={8} overflow="scroll">
						<table className="spreadsheet_custom_header" width={'100%'} style={{ marginTop: '10px' }}>
							<thead style={{ height: '35px' }}>
								<tr>
									<th></th>
									<td>{t('Type of Insurance')}</td>
									{titles?.map((el, i) => {
										if (i === titles.length - 1) {
											return <td>{t(el?.labelEn)}</td>;
										}
										return (
											<td>
												{t(moment(localMonth + 1, 'MM').format('MMMM'))} - {t(el?.labelEn)}
											</td>
										);
									})}
								</tr>
							</thead>
							<tbody>
								{/* Premium */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Gross Premium')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.grossPremium?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.grossPremium?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.grossPremium?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.grossPremium?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.grossPremium?.Total?.map((el, i) => {
										if (i === loadedData?.grossPremium?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Number of Policies')} */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Number of Policies')} (#)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.numPolicies?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.numPolicies?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.numPolicies?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.numPolicies?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.numPolicies?.Total?.map((el, i) => {
										if (i === loadedData?.numPolicies?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Sum Insured')} */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Sum Insured')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.sumInsured?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.sumInsured?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.sumInsured?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.sumInsured?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.sumInsured?.Total?.map((el, i) => {
										if (i === loadedData?.sumInsured?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Gross Claim Incurred')} */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Gross Claim Incurred')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.claimIncurred?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimIncurred?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.claimIncurred?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimIncurred?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.claimIncurred?.Total?.map((el, i) => {
										if (i === loadedData?.claimIncurred?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Gross Claim Paid')} */}
								<tr height={'35px'}>
									<td rowSpan={3}>{t('Gross Claim Paid')} ($)</td>
									<th>{t('General Insurance')}</th>
									{loadedData?.claimPaid?.GENERAL_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimPaid?.GENERAL_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{t('Life Insurance')}</th>
									{loadedData?.claimPaid?.LIFE_INSURANCE?.map((el, i) => {
										if (i === loadedData?.claimPaid?.LIFE_INSURANCE?.length - 1) {
											return <th>{el}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<td className="background-light-concrete">{t('Total')}</td>
									{loadedData?.claimPaid?.Total?.map((el, i) => {
										if (i === loadedData?.claimPaid?.Total?.length - 1) {
											return <th className="background-light-concrete">{el}</th>;
										}
										return <th className="background-light-concrete">{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
							</tbody>
						</table>
					</Box>
				)}
			</DataLoading>
			<Flex direction="column" justifyContent="center" alignItems="flex-start" ml={4} mr={4}>
				<Text fontSize={'15px'} fontWeight={'bold'}>
					{`${t('2. Data Comparison by Insurance Product Line for')} ${t(moment(localMonth + 1, 'MM').format('MMMM'))} ${t(localYear)}`}
				</Text>
				<Text fontSize={'15px'} fontWeight={'bold'} mt={2}>
					{t('2.1 General Insurance Product')}
				</Text>
			</Flex>
			<DataLoading loading={loading} loadedData={data?.getMonthlyFinalReport?.general}>
				{({ loadedData }) => (
					<Box ml={4} mr={4} mt={0} mb={8} overflow="scroll">
						<table className="spreadsheet_custom_header_report" style={{ marginTop: '10px', width: '100%' }}>
							<thead style={{ height: '35px' }}>
								<tr>
									<th></th>
									<td>{t('Year')}</td>
									{genProducts?.map((el) => {
										return <td>{el?.productNameKh}</td>;
									})}
								</tr>
							</thead>
							<tbody>
								{/* Premium */}
								<tr height={'35px'}>
									<td rowSpan={2} style={{ minWidth: '160px' }}>
										{t('Gross Premium')}
									</td>
									<th>{localYear - 1}</th>
									{loadedData?.grosspremium[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.grosspremium[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.grosspremium[localYear]?.map((el, i) => {
										if (i === loadedData?.grosspremium[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Number of Policies')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Number of Policies')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.numpolicies[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.numpolicies[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatNumber(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.numpolicies[localYear]?.map((el, i) => {
										if (i === loadedData?.numpolicies[localYear].length - 1) {
											return <th className="background-light-silver">{formatNumber(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Sum Insured')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Sum Insured')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.suminsured[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.suminsured[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.suminsured[localYear]?.map((el, i) => {
										if (i === loadedData?.suminsured[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Gross Claim Incurred')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Gross Claim Incurred')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.claimincurred[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.claimincurred[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.claimincurred[localYear]?.map((el, i) => {
										if (i === loadedData?.claimincurred[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Gross Claim Paid')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Gross Claim Paid')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.claimpaid[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.claimpaid[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.claimpaid[localYear]?.map((el, i) => {
										if (i === loadedData?.claimpaid[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
							</tbody>
						</table>
					</Box>
				)}
			</DataLoading>
			<Flex justifyContent="flex-start" alignItems="center" ml={4} mr={4}>
				<Text fontSize={'15px'} fontWeight={'bold'}>
					{t('2.2 Life Insurance Product')}
				</Text>
			</Flex>
			<DataLoading loading={loading} loadedData={data?.getMonthlyFinalReport?.life}>
				{({ loadedData }) => (
					<Box ml={4} mr={4} mt={0} mb={8} overflow="scroll">
						<table className="spreadsheet_custom_header_report" style={{ marginTop: '10px', width: '100%' }}>
							<thead style={{ height: '35px' }}>
								<tr>
									<th></th>
									<td>{t('Year')}</td>
									{lifeProducts?.map((el) => {
										return <td>{el?.productNameKh}</td>;
									})}
								</tr>
							</thead>
							<tbody>
								{/* Premium */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Gross Premium')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.grosspremium[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.grosspremium[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.grosspremium[localYear]?.map((el, i) => {
										if (i === loadedData?.grosspremium[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Number of Policies')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Number of Policies')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.numpolicies[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.numpolicies[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatNumber(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.numpolicies[localYear]?.map((el, i) => {
										if (i === loadedData?.numpolicies[localYear].length - 1) {
											return <th className="background-light-silver">{formatNumber(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Sum Insured')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Sum Insured')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.suminsured[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.suminsured[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.suminsured[localYear]?.map((el, i) => {
										if (i === loadedData?.suminsured[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Gross Claim Incurred')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Gross Claim Incurred')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.claimincurred[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.claimincurred[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.claimincurred[localYear]?.map((el, i) => {
										if (i === loadedData?.claimincurred[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* {t('Gross Claim Paid')} */}
								<tr height={'35px'}>
									<td rowSpan={2}>{t('Gross Claim Paid')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.claimpaid[localYear - 1]?.map((el, i) => {
										if (i === loadedData?.claimpaid[localYear - 1].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.claimpaid[localYear]?.map((el, i) => {
										if (i === loadedData?.claimpaid[localYear].length - 1) {
											return <th className="background-light-silver">{formatCurrency(toFixedTrunc(el, 3))}</th>;
										}
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
							</tbody>
						</table>
					</Box>
				)}
			</DataLoading>
			<Box ml={4} mr={4} mt={0} mb={0}>
				<SimpleGrid columns={[1, null, 2]} spacingX={8} spacingY={8}>
					<GiGrossPremiumByProductLine
						loading={loading}
						dataset={data?.getMonthlyFinalReport?.generalBarDataset}
						title={'Gross Premium General Insurance By Product Line'}
						fileName={'gross-premium-general-insurance-by-product-line.jpg'}
					/>
					<GiLiFinalGrowthByProductLine
						loading={loading}
						dataset={data?.getMonthlyFinalReport?.generalDataSet}
						title={'Market Share By Product Line for General Insurance'}
						fileName={'general-market-share-by-product-line.jpg'}
					/>
					<GiGrossPremiumByProductLine
						loading={loading}
						dataset={data?.getMonthlyFinalReport?.lifeBarDataset}
						title={'Gross Premium Life Insurance By Product Line'}
						fileName={'gross-premium-life-insurance-by-product-line.jpg'}
					/>
					<GiLiFinalGrowthByProductLine
						loading={loading}
						dataset={data?.getMonthlyFinalReport?.lifeDataSet}
						title={'Market Share By Product Line for Life Insurance'}
						fileName={'life-market-share-by-product-line.jpg'}
					/>
				</SimpleGrid>
			</Box>
		</>
	);
}
