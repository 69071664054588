/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Tbody, Tfoot, Tr, Th, Td, Button, useDisclosure, TableContainer, Thead } from '@chakra-ui/react';
import { faSlidersH, faPrint, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import ReactPaginate from 'react-paginate';
import CompanySubmissionReportFilter from '../../components/Filter/CompanySubmissionReportFilter';
import LoadingTable from '../../components/Table/Loading';
import PrintLayout from '../../components/Print/PrintLayout';
import CompanyName from '../../components/CompanyName';
import LabelDate from '../../components/LabelDate';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import useStore from '../../store';
import moment from 'moment';
import c from '../../constant';
import ReprotLayout from '../../components/Report/ReportLayout';

const QUERY_REPORTS = gql`
	query getCompanySubmissionReports(
		$pagerInput: PagerInput!
		$fromDate: DateTime
		$toDate: DateTime
		$companyName: String
		$type: CompanySubmissionReportTypes
		$reportType: CompanySubmissionReportTypesOfReport
		$newReportType: String
		$read: Boolean
		$spam: Boolean
	) {
		getCompanySubmissionReports(
			pagerInput: $pagerInput
			fromDate: $fromDate
			toDate: $toDate
			companyName: $companyName
			type: $type
			reportType: $reportType
			newReportType: $newReportType
			read: $read
			spam: $spam
		) {
			companySubmissionReports {
				id
				companyName
				createdAt
				type
				reportType
				spam
				company {
					id
					name
					nameKhmer
					logo
				}
				notification {
					read
				}
				assignTo {
					user {
						id
						username
						fullName
						fullNameKhmer
						avatar
					}
					createdBy
				}
				newTypeDetail {
					titleKh
					title
				}
			}
			countUnreadCompanySubmissionReports
			totalPages
			totalRows
		}
	}
`;

function Report() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const type = urlParams.get('type') || null;
	const page = urlParams.get('page') || 1;
	const companyName = urlParams.get('companyName') || null;
	const fromDate = urlParams.get('fromDate') || null;
	const toDate = urlParams.get('toDate') || null;
	const read = urlParams.get('read') || null;
	const newReportType = urlParams.get('newReportType') || null;
	const showSpam = urlParams.get('showSpam') || null;
	const companyType = urlParams.get('companyType') || null;
	const companyId = urlParams.get('companyId') || null;
	const { t } = useTranslation();
	const { push } = useHistory();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loadReports, { loading, data }] = useLazyQuery(QUERY_REPORTS);

	moment.locale(lang === 'kh' ? 'km' : 'en-gb');

	useEffect(() => {
		window.scrollTo(0, 0);
		const limit = 10;
		const offset = page ? (page - 1) * limit : 0;
		const pagerInput = { limit, offset };
		loadReports({
			variables: {
				companyName,
				pagerInput,
				fromDate: datePickerFromToToDate(fromDate),
				toDate: datePickerFromToToDate(toDate),
				read: read === 'false' ? false : read === 'true' ? true : null,
				type: type?.toUpperCase(),
				newReportType: newReportType === 'all' ? null : newReportType,
				spam: showSpam === 'true' ? true : null,
			},
		});
	}, [page, type, fromDate, toDate, read, companyName, showSpam, newReportType]);

	function handlePageClick(data) {
		let url = `?type=${type?.toLowerCase()}&page=${data?.selected + 1}`;

		if (newReportType) {
			url += `&newReportType=${newReportType}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (fromDate && toDate) {
			url += `&fromDate=${fromDate}&toDate=${toDate}`;
		}

		if (read) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}

		if (companyType) {
			url = url + '&companyType=' + companyType;
		}

		if (companyId) {
			url = url + '&companyId=' + companyId;
		}

		push(`/company-submission-report/report${url}`);
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Company Report',
								path: '/company-submission-report',
							},
							{
								name: type,
								path: '#',
							},
						]}
					/>
					<Box mt="19px" w="100%">
						<ReprotLayout activeTab={type?.toLocaleLowerCase()} reportId={newReportType}>
							<Box w="100%">
								<Center className="show-only-printing">
									<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
										{t('Company Report')}
									</Text>
								</Center>
								<Center w="100%">
									<Box
										w="100%"
										bg="white"
										borderRadius={c.borderRadius}
										border="1px solid #dbdbdb"
										boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
										pb="32px"
										minH="450px"
									>
										<Flex
											className="proposal-detail-menu-header hidden-print"
											justifyContent="space-between"
											alignItems="center"
											bg="#FAFAFA"
											p="16px"
											pt="8px"
											pb="8px"
											mb="8px"
											borderBottom="1px solid #dbdbdb"
										>
											<Flex justifyContent="center" alignItems="center">
												<Text fontSize="lg" fontWeight="bold">
													{t('List')}
												</Text>
											</Flex>
											<Flex justifyContent="flex-start" alignItems="center">
												<Button
													mr="2"
													onClick={() => window?.print()}
													leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
													colorScheme="gray"
													variant="solid"
													size="sm"
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7"
												>
													{t('Print')}
												</Button>
												<Button
													onClick={onOpen}
													leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
													colorScheme={`${companyName || fromDate || toDate || read || showSpam ? 'yellow' : 'gray'}`}
													variant="solid"
													size="sm"
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7}"
												>
													{t('Filter')}
												</Button>
											</Flex>
										</Flex>
										<Box className="narrow-space-table" p={4} pt={0} pb={0}>
											<TableContainer>
												<Table variant="simple" w="100%">
													<Thead>
														<Tr>
															<Th>{t('Company')}</Th>
															<Th isNumeric>{t('Created At')}</Th>
															{/* <Th isNumeric>{t('Type')}</Th> */}
														</Tr>
													</Thead>
													<Tbody>
														{loading && <LoadingTable column={2} row={10} />}
														{!loading && data?.getCompanySubmissionReports?.companySubmissionReports.length <= 0 && (
															<Tr>
																<Td colSpan={4}>
																	<Flex direction="column" justifyContent="center" alignItems="center" p={8}>
																		<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 48, color: '#CBD5E0' }} />
																		<Text fontSize="sm" fontWeight="md" color="gray.500" mt={3}>
																			{t('No Data')}
																		</Text>
																	</Flex>
																</Td>
															</Tr>
														)}
														{data?.getCompanySubmissionReports?.companySubmissionReports?.map((item, index) => (
															<Tr
																key={`item-${index}`}
																_hover={{ backgroundColor: 'gray.200' }}
																cursor="pointer"
																onMouseDown={() =>
																	push(
																		`/company-submission-report/detail?id=${item?.id}&type=${type}&newReportType=${newReportType}`
																	)
																}
																backgroundColor={
																	item?.notification ? (item?.notification?.read ? 'white' : 'gray.100') : 'white'
																}
															>
																<Td maxW="300px">
																	<Text noOfLines={1} mr="4">
																		<CompanyName company={item?.company} companyName={item?.companyName} />
																	</Text>
																</Td>
																<Td isNumeric>
																	<LabelDate input={item?.createdAt} outputFormat={c.longDateFormat} />
																</Td>
																{/* <Td isNumeric>
																	<ReportType type={item?.type} />
																	<>
																		{item?.spam && (
																			<>
																				<Badge ml={1} colorScheme="red">
																					{t('Spam')}
																				</Badge>
																			</>
																		)}
																	</>
																</Td> */}
															</Tr>
														))}
													</Tbody>
													<Tfoot className="hidden-print">
														<Tr>
															<Th>{t('Company')}</Th>
															<Th isNumeric>{t('Created At')}</Th>
															{/* <Th isNumeric>{t('Type')}</Th> */}
														</Tr>
													</Tfoot>
												</Table>
											</TableContainer>
											<Flex className="hidden-print" justifyContent="flex-end" alignItems="center">
												<Text fontWeight="semibold" fontSize="sm">
													{t('Total Rows')}: <b style={{ color: '#4A5568' }}>{data?.getCompanySubmissionReports?.totalRows || 0}</b>
												</Text>
											</Flex>
											{data?.getCompanySubmissionReports?.totalPages > 1 && (
												<Flex className="hidden-print" justifyContent="flex-end" alignItems="center" w="100%" mt={4} height="46px">
													<ReactPaginate
														previousLabel={<ChevronLeftIcon fontSize="20px" />}
														nextLabel={<ChevronRightIcon fontSize="20px" />}
														breakLabel={'...'}
														breakClassName={'break-me'}
														disableInitialCallback={true}
														pageCount={data?.getCompanySubmissionReports?.totalPages}
														marginPagesDisplayed={2}
														pageRangeDisplayed={3}
														onPageChange={handlePageClick}
														containerClassName={'pagination'}
														activeClassName={'active'}
														initialPage={(page - 1) | 0}
													/>
												</Flex>
											)}
										</Box>
									</Box>
								</Center>
							</Box>
						</ReprotLayout>
					</Box>
				</Container>
				{isOpen && (
					<CompanySubmissionReportFilter
						onCallback={(e) => {
							let url = `/company-submission-report/report${e}`;

							if (companyType) {
								url = url + '&companyType=' + companyType;
							}

							if (companyId) {
								url = url + '&companyId=' + companyId;
							}

							push(url);
						}}
						isOpen={true}
						onClose={onClose}
						fromDate={fromDate}
						toDate={toDate}
						read={read}
						companyName={companyName}
						type={type?.toLocaleLowerCase()}
						showSpam={showSpam === 'true' ? true : false}
						newReportType={newReportType}
					/>
				)}
			</>
		</PrintLayout>
	);
}

export default Report;
