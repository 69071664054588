import { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { checkSelectedActiveColumn } from '../../../utils/market-development';
import { calculateSFPCells } from '../../../utils/ews-sfp-utils';
import Spreadsheet from 'react-spreadsheet';
import EWSLabels from './ews-template-labels';
import { formatEWS } from '../../../utils/formatEWS';
import formatEWSkh from '../../../utils/formatEWSkh';

export default function EWSSFP({ readOnly = false, code = null, labels = [], cells = [], onChange = ({ code, cells }) => null }) {
	const { t } = useTranslation();
	const [dataCells, setDataCells] = useState([]);
	const [active, setActive] = useState(null);

	useEffect(() => {
		const highlightedRows = [24, 36, 54, 56];
		setDataCells(
			cells.map((row, rowIndex) =>
				row.map((cell, colIndex) => ({
					...cell,
					value: highlightedRows.includes(rowIndex)
						? colIndex === 0
							? formatEWS(cell.value)
							: colIndex === 1
							? formatEWSkh(cell.value)
							: cell.value
						: cell.value,
					className: rowIndex === 24 || rowIndex === 36 || rowIndex === 54 || rowIndex === 56 ? 'highlighted-row' : '',
				}))
			)
		);
	}, [cells]);

	return (
		<>
			<Box>
				<Text fontSize="large" fontWeight="bold" mb={4}>
					{t('Statement of Financial Position')}
				</Text>
				<Box className="ews-container">
					<div className="ews-1-row-head" />
					<table className="spreadsheet_custom_header">
						<thead>
							<tr style={{ height: '34px' }}>
								<td className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 1) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
							</tr>
						</thead>
					</table>
					<div>
						<EWSLabels type="EWS" labels={labels} active={active} />
						<Box>
							<Flex alignItems="center" mt="0px" h="0px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
							<Spreadsheet
								data={dataCells}
								onChange={(updatedCells) => {
									if (readOnly) {
										onChange({
											code,
											cells: cells?.reduce((pre, cur, i) => {
												let localPre = pre;
												localPre.push([
													{ value: formatEWS(cur[0]?.value), readOnly: true },
													{ value: formatEWSkh(cur[1]?.value), readOnly: true },
												]);
												return localPre;
											}, []),
										});
									} else {
										onChange(calculateSFPCells({ code, cells: updatedCells, numLabel: labels?.length }));
									}
								}}
								onActivate={(e) => setActive({ ...e, t: 'EWS' })}
								hideRowIndicators
								hideColumnIndicators
							/>
						</Box>
					</div>
				</Box>
			</Box>
		</>
	);
}
