import { useEffect, useState } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { checkSelectedActiveColumn } from '../../../utils/market-development';
import { calculateSOCECells } from '../../../utils/ews-soce-utils';
import Spreadsheet from 'react-spreadsheet';
import EWSLabels from './ews-template-labels';
import { formatEWS } from '../../../utils/formatEWS';
import formatEWSkh from '../../../utils/formatEWSkh';

export default function EWSSOCE({ readOnly = false, code = null, labels = [], cells = [], onChange = ({ code, cells }) => null }) {
	const { t } = useTranslation();
	const [dataCells, setDataCells] = useState([]);
	const [active, setActive] = useState(null);

	useEffect(() => {
		const highlightedRows = [7];
		const highlightedColEn = [0, 2, 4, 6, 8, 10, 12];
		const highlightedColKh = [1, 3, 5, 7, 9, 11];
		setDataCells(
			cells.map((row, rowIndex) =>
				row.map((cell, colIndex) => ({
					...cell,
					value: highlightedRows.includes(rowIndex)
						? highlightedColEn.includes(colIndex)
							? formatEWS(cell.value)
							: highlightedColKh.includes(colIndex)
							? formatEWSkh(cell.value)
							: cell.value
						: cell.value,
					className: rowIndex === 7 ? 'highlighted-row' : '',
				}))
			)
		);
	}, [cells]);

	return (
		<>
			<Box>
				<Text fontSize="large" fontWeight="bold" mb={4}>
					{t('Statement of Changes in Equity')}
				</Text>
				<Box className="ews-container">
					<div className="ews-2-row-head" />
					<table className="spreadsheet_custom_header">
						<thead>
							<tr style={{ height: '34px' }}>
								<td colSpan="2" className="ews-background-light-silver">
									{t('Share capital')}
								</td>
								<td colSpan="2" className="ews-background-light-silver">
									{t('Share premium')}
								</td>
								<td colSpan="2" className="ews-background-light-silver singleLine">
									{t('Deposits for future share subscription')}
								</td>
								<td colSpan="2" className="ews-background-light-silver singleLine">
									{t('Revaluation reserves')}
								</td>
								<Tooltip label={<>{t('Retained earnings/accumulated losses')}</>}>
									<td colSpan="2" className="ews-background-light-silver singleLine">
										{t('Retained earnings/accumulated losses')}
									</td>
								</Tooltip>
								<td colSpan="2" className="ews-background-light-silver singleLine">
									{t('Other components of equity')}
								</td>
								<td colSpan="2" className="ews-background-light-silver">
									{t('Total')}
								</td>
							</tr>
							<tr style={{ height: '34px' }}>
								<td className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 1) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
								<td className={checkSelectedActiveColumn(active, 2) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 3) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
								<td className={checkSelectedActiveColumn(active, 4) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 5) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
								<td className={checkSelectedActiveColumn(active, 6) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 7) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
								<td className={checkSelectedActiveColumn(active, 8) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 9) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
								<td className={checkSelectedActiveColumn(active, 10) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 11) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
								<td className={checkSelectedActiveColumn(active, 12) ? 'active-column' : 'ews-background-light-silver'}>{t('USD')}</td>
								<td className={checkSelectedActiveColumn(active, 13) ? 'active-column' : 'ews-background-light-silver'}>{t(`KHR'000`)}</td>
							</tr>
						</thead>
					</table>
					<div>
						<EWSLabels type="EWS" labels={labels} active={active} />
						<Box>
							<Flex alignItems="center" mt="0px" h="0px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
							<Spreadsheet
								data={dataCells}
								onChange={(dataCells) => {
									if (readOnly) {
										onChange({
											code,
											cells: cells?.reduce((pre, cur, i) => {
												let localPre = pre;
												localPre.push([
													{ value: cur[0]?.value, readOnly: true },
													{ value: cur[1]?.value, readOnly: true },
													{ value: cur[2]?.value, readOnly: true },
													{ value: cur[3]?.value, readOnly: true },
													{ value: cur[4]?.value, readOnly: true },
													{ value: cur[5]?.value, readOnly: true },
													{ value: cur[6]?.value, readOnly: true },
													{ value: cur[7]?.value, readOnly: true },
													{ value: cur[8]?.value, readOnly: true },
													{ value: cur[9]?.value, readOnly: true },
													{ value: cur[10]?.value, readOnly: true },
													{ value: cur[11]?.value, readOnly: true },
													{ value: cur[12]?.value, readOnly: true },
													{ value: cur[13]?.value, readOnly: true },
												]);
												return localPre;
											}, []),
										});
									} else {
										onChange(calculateSOCECells({ code, cells: dataCells, numLabel: labels?.length }));
									}
								}}
								onActivate={(e) => setActive({ ...e, t: 'EWS' })}
								hideRowIndicators
								hideColumnIndicators
							/>
						</Box>
					</div>
				</Box>
			</Box>
		</>
	);
}
