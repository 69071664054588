/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
	Badge,
	Box,
	Button,
	Center,
	Container,
	Flex,
	Text,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Radio,
	RadioGroup,
	Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FaWindowRestore } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CompanyBadge } from '../../../components/Other';
import PrintLayout from '../../../components/Print/PrintLayout';
import ReactPaginate from 'react-paginate';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import Filter from '../../../components/MarketDevelopment/Miscellaneous/filter';
import CompanyName from '../../../components/CompanyName';
import LabelDate from '../../../components/LabelDate';
import DetailPage from './detail';
import ExcelInsuranceSales from '../../../components/MarketDevelopment/MonthlySale/Excel/ExcelInsuranceSales';
import useStore from '../../../store';
import composeQueries from '../../../utils/composeQueries';
import c from '../../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Q_REPORTS = gql`
	query get($limit: Float!, $offset: Float!, $companyId: String, $companyType: InsuranceInstitutionsType, $month: Float, $year: Float, $isEditing: Boolean) {
		getMonthlySaleReports(
			pagerInput: { limit: $limit, offset: $offset }
			companyId: $companyId
			companyType: $companyType
			month: $month
			year: $year
			isEditing: $isEditing
		) {
			list {
				id
				company {
					id
					name
					nameKhmer
					type
					logo
				}
				month
				year
				reportType
				createdAt
				isEditing
				editingUpdatedAt
				editingUpdatedBy
				editingDueDate
				editingDescription
				notification {
					read
				}
				penalties {
					id
					active
				}
				contribution {
					rate
					grossPremium
				}
			}
			totalRows
			totalPages
		}
	}
`;

function MonthlySaleReport() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const page = urlParams.get('page') ? urlParams.get('page') : 1;
	const companyId = urlParams.get('companyId') ? urlParams.get('companyId') : null;
	const companyType = urlParams.get('companyType') ? urlParams.get('companyType') : null;
	const month = urlParams.get('month') ? urlParams.get('month') : null;
	const year = urlParams.get('year') ? urlParams.get('year') : null;
	const editing = urlParams.has('editing', true);
	const period = urlParams.get('period') || null;
	const reportId = urlParams.get('reportId') || null;
	const { push } = useHistory();
	const { t } = useTranslation();
	const [getMonthlySaleReports, { loading: loadingGet, data: dataGet, error: errorGet }] = useLazyQuery(Q_REPORTS, { fetchPolicy: 'no-cache' });
	const readReport = useStore((state) => state.readReport);
	const [openModalView, setOpenModalView] = useState(false);
	const [modalReportType, setModalReportType] = useState(false);
	const [openType, setOpenType] = useState('page');

	useEffect(() => {
		load();
	}, [page, companyId, companyType, month, year, editing]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	function load() {
		const limit = 10;
		const offset = parseInt(page ? (page - 1) * limit : 0) || 0;
		const variables = {
			offset: offset < 0 ? 0 : offset,
			limit,
			companyId,
			companyType,
			month: parseInt(month),
			year: parseInt(year),
			isEditing: editing ? true : null,
		};
		getMonthlySaleReports({ variables });
	}

	function handlePageChanged({ selected }) {
		let page = selected + 1;
		let url = '?period=' + period + '&reportId' + reportId + '&page=' + page;

		if (companyId !== null && companyId !== undefined) {
			url += `&companyId=${companyId}`;
		}

		if (companyType !== null && companyType !== undefined) {
			url += `&companyType=${companyType}`;
		}

		if (month !== null && month !== undefined) {
			url += `&month=${month}`;
		}

		if (year !== null && year !== undefined) {
			url += `&year=${year}`;
		}

		if (editing !== null && editing !== undefined) {
			url += `&editing=${editing}`;
		}

		push(`/market-development-data/monthly-sale-report${url}`);
	}

	function openDetail(e) {
		if (openType === 'page') {
			const params = '?id=' + e?.id + '&reportType=' + e?.reportType + '&period=' + period + '&reportId=' + reportId;
			if (e?.notification?.read === false) {
				readReport(reportId);
			}
			push('/market-development-data/monthly-sale-report/detail' + composeQueries(params, { companyType, companyId }));
		} else {
			setOpenModalView(e?.id);
			setModalReportType(e?.reportType);
			if (e?.notification?.read === false) {
				readReport(reportId);
			}
		}
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Company Report',
								path: '/company-submission-report',
							},
							{
								name: period,
								path: composeQueries('/market-development-data?period=' + period + '&reportId=' + reportId, { companyType, companyId }),
							},
							{
								name: 'Sale Report',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t('Company Report')}
						</Text>
					</Center>
					<>
						{dataGet?.getMonthlySaleReports && (
							<Center mt="5">
								<Box
									w="100%"
									bg="white"
									borderRadius={c.borderRadius}
									border="1px solid #dbdbdb"
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									pb="32px"
								>
									<Flex
										className="hidden-print responsive-header-for-table"
										bg="#FAFAFA"
										p="16px"
										pt="8px"
										pb="8px"
										mb="16px"
										alignItems="center"
										justifyContent="space-between"
										borderBottom="1px solid #dbdbdb"
									>
										<Text fontSize="x-large" fontWeight="bold">
											{t('Insurance Sales Report')}
										</Text>
										<Flex>
											<Flex mr="2">
												<ExcelInsuranceSales />
											</Flex>
											<Button
												mr="2"
												onClick={() => window?.print()}
												leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
												colorScheme="gray"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
											>
												{t('Print')}
											</Button>
											<Filter
												period={period}
												reportId={reportId}
												companyType={companyType}
												companyId={companyId}
												month={month}
												year={year}
												editing={editing}
												onCallback={(url) => push(`/market-development-data/monthly-sale-report${url}`)}
											/>
										</Flex>
									</Flex>
									<Box p="16px" pt="0">
										{loadingGet && (
											<Flex justifyContent="center" alignItems="center">
												<Text>{t('Loading')}</Text>
											</Flex>
										)}
										{!loadingGet && dataGet?.getMonthlySaleReports?.list?.length <= 0 && (
											<Flex direction="column" alignItems="center" justifyContent="center" pt={16} pl={4} pr={4} pb={16}>
												<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 56, color: '#A0AEC0' }} />
												<Text fontSize="lg" fontWeight="bold" color="gray.600" mt={6}>
													{t('No Data')}
												</Text>
											</Flex>
										)}
										{!loadingGet && dataGet?.getMonthlySaleReports?.list?.length > 0 && (
											<>
												<Table variant="simple" className="table-company-proposal-list responsive-table-container">
													<Thead>
														<Tr className="td-as-th">
															<Th align="left">{t('Company')}</Th>
															<Th align="left">{t('Type')}</Th>
															<Th align="left">{t('Monthly')}</Th>
															<Th align="left">{t('Year')}</Th>
															<Th align="left">{t('Submitted At')}</Th>
															<Th align="right">{t('Status')}</Th>
														</Tr>
													</Thead>
													<Tbody>
														{dataGet?.getMonthlySaleReports?.list?.map((e, i) => {
															return (
																<Tr
																	key={`report-item-${i}`}
																	className={e?.notification?.read === false ? 'new' : 'read'}
																	onClick={() => openDetail(e)}
																>
																	<Td align="left">
																		<CompanyName company={e?.company} />
																	</Td>
																	<Td align="left">
																		<CompanyBadge type={e?.company?.type} />
																	</Td>
																	<Td align="left">
																		<LabelDate input={parseInt(e?.month) + 1} inputFormat="M" outputFormat="MMMM" />
																	</Td>
																	<Td align="left">
																		<LabelDate input={parseInt(e?.year)} inputFormat="Y" outputFormat="YYYY" />
																	</Td>
																	<Td align="left">
																		<LabelDate input={e?.createdAt} outputFormat={c.dateTimeFormat} />
																	</Td>
																	<Td align="right">
																		{e?.penalties?.length > 0 && (
																			<Badge mr={e?.isEditing ? 1 : 0} colorScheme="red" variant="solid">
																				{t('Penalized')}
																			</Badge>
																		)}
																		{e?.isEditing ? (
																			<Badge colorScheme="orange" variant="solid">
																				{t('Editing')}
																			</Badge>
																		) : (
																			<>{e?.penalties?.length > 0 ? <></> : <>-</>}</>
																		)}
																	</Td>
																</Tr>
															);
														})}
													</Tbody>
													<tfoot className="responsive-footer hidden-print">
														<Tr className="td-as-th">
															<Th align="left">{t('Company')}</Th>
															<Th align="left">{t('Type')}</Th>
															<Th align="left">{t('Monthly')}</Th>
															<Th align="left">{t('Year')}</Th>
															<Th align="left">{t('Submitted At')}</Th>
															<Th align="right">{t('Status')}</Th>
														</Tr>
													</tfoot>
												</Table>
												<Flex className="hidden-print" alignItems="flex-end" direction="column" mt={2} pl={6} pr={6}>
													<Text fontSize="sm" fontWeight="semibold" color="gray.600">
														{`${t('Total Rows')}: ${dataGet?.getMonthlySaleReports?.totalRows}`}
													</Text>
												</Flex>
												{dataGet?.getMonthlySaleReports?.totalPages > 1 && (
													<Flex className="hidden-print" justifyContent="flex-end" mt={8} pl={0} pr={0}>
														<ReactPaginate
															previousLabel={<ChevronLeftIcon fontSize="20px" />}
															nextLabel={<ChevronRightIcon fontSize="20px" />}
															breakLabel={'...'}
															breakClassName={'break-me'}
															disableInitialCallback={true}
															pageCount={dataGet?.getMonthlySaleReports?.totalPages}
															marginPagesDisplayed={2}
															pageRangeDisplayed={3}
															onPageChange={handlePageChanged}
															containerClassName={'pagination'}
															activeClassName={'active'}
															initialPage={(page - 1) | 0}
														/>
													</Flex>
												)}
											</>
										)}
									</Box>
								</Box>
							</Center>
						)}
					</>
				</Container>
				<Flex
					_hover={{ ml: '0px !important' }}
					transition="all ease-in 0.1s"
					ml="-272px"
					alignItems="center"
					position="absolute"
					bg="white"
					height="32px"
					width="300px"
					top="64px"
				>
					<Flex width="100%" p={4}>
						<Text fontWeight="bold" mr="4">
							{t('Open')}
						</Text>
						<RadioGroup onChange={setOpenType} size="sm" mr="4" value={openType}>
							<Stack direction="row">
								<Radio value="page">{t('Page')}</Radio>
								<Radio value="modal">{t('Popup')}</Radio>
							</Stack>
						</RadioGroup>
					</Flex>
					<Flex justifyContent="center" alignItems="center" width="32px" height="32px" backgroundColor="white">
						<FaWindowRestore w="24px" h="24px" color="black" />
					</Flex>
				</Flex>
				{openModalView && (
					<Modal size="7xl" isOpen={openModalView} onClose={() => setOpenModalView(false)}>
						<ModalOverlay />
						<ModalContent borderRadius={c.borderRadius}>
							<ModalCloseButton />
							<ModalBody>
								<DetailPage modalReportType={modalReportType} modalId={openModalView} />
							</ModalBody>
						</ModalContent>
					</Modal>
				)}
			</>
		</PrintLayout>
	);
}

export default MonthlySaleReport;
