/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListLoading from '../../components/ListLoading';
import useCompanyReportStore from '../../store/company-report-persistent-store';
import useShowArchivedReport from '../../store/show-archived-report-store';
import useStore from '../../store';
import c from '../../constant';

const QUERY_REPORTS = gql`
	query get($period: String!) {
		getPublicArchivedCompanyReportTypes(period: $period) {
			id
			title
			titleKh
			period
			departments
		}
	}
`;

export default function ReportArchivedLayout({ period = null, reportId = null }) {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const { currentLang: lang } = useStore((state) => state?.currentLang);
	const { archived, setArchived } = useShowArchivedReport();
	const { companyType, companyId } = useCompanyReportStore();
	const [reports, setReports] = useState([]);
	const [loadData, { data, loading }] = useLazyQuery(QUERY_REPORTS);

	useEffect(load, [archived, period]);

	useEffect(() => {
		const roles = user?.role.split(',') || [];
		const userDepartments = user?.department?.split(',') || [];
		setReports(
			data?.getPublicArchivedCompanyReportTypes?.reduce((pre, cur) => {
				const localPre = pre;
				const reportDepartments = cur?.departments?.split(',') || [];
				const status = userDepartments.some((department) => reportDepartments.includes(department));
				if (roles?.includes('admin') || status) {
					localPre.push({
						id: cur?.id,
						title: cur?.title,
						titleKh: cur?.titleKh,
						period: cur?.period,
						path: '/company-submission-report/report?type=' + cur?.period?.toLowerCase() + '&page=1&newReportType=' + cur?.id,
					});
				}
				return localPre;
			}, [])
		);
	}, [data]);

	function load() {
		if (archived && period) {
			loadData({ variables: { period: period?.toUpperCase() } });
		}
	}

	return (
		<Box width="100%">
			<Checkbox mb={2} borderColor="blue.500" isChecked={archived} fontWeight="bold" onChange={() => setArchived(!archived)}>
				{t('Show archived report')}
			</Checkbox>
			{archived && (
				<ListLoading loading={loading} loadedList={reports}>
					{({ loadedList }) => (
						<>
							{loadedList?.map((el, i) => (
								<Flex
									key={i}
									onMouseDown={() => {
										let params = {};
										if (companyType) {
											params['companyType'] = companyType || null;
										}
										if (companyId) {
											params['companyId'] = companyId || null;
										}
										let queries =
											Object.keys(params)
												.map((key) => key + '=' + params[key])
												.join('&') || '';

										push(el?.path + (queries ? '&' + queries : ''));
									}}
									width="100%"
									alignItems="center"
									bg={`${reportId === el?.id ? 'rgba(52, 152, 219,.1)' : 'white'}`}
									borderRadius={c.borderRadius}
									border={`1px solid ${reportId === el?.id ? '#3498db' : '#dbdbdb'}`}
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									p="2"
									cursor="pointer"
									mb="2"
								>
									<Text fontWeight="bold" color="black.500">
										{lang === 'kh' ? el?.titleKh : el?.title}
									</Text>
									<Text fontWeight="bold" color="blue.500">
										&nbsp;({t('Archive')})
									</Text>
								</Flex>
							))}
						</>
					)}
				</ListLoading>
			)}
		</Box>
	);
}
