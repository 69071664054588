import create from 'zustand';
import { persist } from 'zustand/middleware';

const useShowArchivedReport = create(
	persist(
		(set) => ({
			archived: false,
			setArchived: (value) => set({ archived: value || false }),
		}),
		{ name: 'show-archived-report' }
	)
);

export default useShowArchivedReport;
