/* eslint-disable react-hooks/exhaustive-deps */
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Avatar,
	Badge,
	Box,
	Button,
	Center,
	Container,
	Flex,
	SimpleGrid,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	Tooltip,
	useDisclosure,
	useToast,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { EWSTypeStatement, EWSTypeTemplate } from '../../utils/ews';
import { detailSFPCells } from '../../utils/ews-sfp-utils';
import { detailSCFCells } from '../../utils/ews-scf-utils';
import { detailSPLOCICells } from '../../utils/ews-sploci-utils';
import { detailSOCECells } from '../../utils/ews-soce-utils';
import { detailORDCells } from '../../utils/ews-ord-utils';
import { detailIRCells } from '../../utils/ews-ir-utils';
import { CompanyBadge } from '../../components/Other';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import SpreadSheetKeyValuePair from '../../components/MarketDevelopment/Miscellaneous/spreadsheet-key-value-pair';
import CompanyName from '../../components/CompanyName';
import LabelDate from '../../components/LabelDate';
import PrintLayout from '../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import UserDislayNameById from '../../components/UserDislayNameById';
import EWSAttachmentDetail from './components/ews-attachment';
import EWSStatementEditableModal from './components/ews-edit-modal';
import EWSSFP from './components/ews-sfp';
import EWSSPLOCI from './components/ews-sploci';
import EWSSCF from './components/ews-scf';
import EWSSOCE from './components/ews-soce';
import EWSORD from './components/ews-ord';
import EWSIR from './components/ews-ir';
import Loading from '../../components/Loading';
import c from '../../constant';
import useStore from '../../store';
import SelectUserModal from '../../components/Modal/SelectUserModal';
import ExportExcelGeneral from '../../components/EarlyWarning/QuarterlyClaim/ExcelGeneral';

const Q_DETAIL = gql`
	query get($statementId: String!) {
		getEarlyWarningStatementById(statementId: $statementId) {
			id
			company {
				id
				name
				nameKhmer
				type
				logo
			}
			type
			year
			attachmentFile
			createdAt
			createdBy
			updatedAt
			updatedBy
			isEditing
			editingUpdatedAt
			editingUpdatedBy
			editingDueDate
			editingDescription
			assigns {
				id
				read
				assignee {
					id
					username
					fullName
					fullNameKhmer
					avatar
					department
					office
				}
				createdAt
				createdBy
			}
			templates {
				id
				statementId
				template {
					id
					titleKhmer
					titleEnglish
					code
					order
				}
				labels {
					id
					statementTemplateId
					templateLabel {
						id
						titleKhmer
						titleEnglish
						code
						level
						order
						template {
							id
							titleKhmer
							titleEnglish
							code
							order
						}
					}
					values {
						id
						statementTemplateLabelId
						value
						currency
						isMillion
						isForeignCurrency
						soceCol
					}
				}
			}
		}
	}
`;

const Q_TEMPLATES = gql`
	query get($report: EarlyWarningTypeReport!) {
		getEarlyWarningTemplates(report: $report) {
			id
			titleKhmer
			titleEnglish
			code
			order
			labels {
				id
				code
				titleKhmer
				titleEnglish
				level
				order
				readOnly
			}
		}
	}
`;

const M_ADD_USER = gql`
	mutation remove($statementId: String!, $assigneeId: String!) {
		addUserToEarlyWarningStatement(statementId: $statementId, assigneeId: $assigneeId)
	}
`;

const M_REMOVE_USER = gql`
	mutation remove($statementId: String!, $assigneeId: String!) {
		removeUserFromEarlyWarningStatement(statementId: $statementId, assigneeId: $assigneeId)
	}
`;

let removingAssignee = null;

function EarlyWarningDetail() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') || null;
	const period = urlParams.get('period') || null;
	const reportId = urlParams.get('reportId') || null;
	const report = urlParams.get('report') || null;
	const editModal = useDisclosure();
	const addAssigneeModal = useDisclosure();
	const removeAssigneeModal = useDisclosure();
	const { t } = useTranslation();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const { currentLang: lang } = useStore((state) => state?.currentLang);
	const [detail, setDetail] = useState(null);
	const [year, setYear] = useState(null);
	const [month, setMonth] = useState(null);
	const [objects, setObjects] = useState(null);
	const [fetchDetail, { error: errorDetail, data: dataDetail }] = useLazyQuery(Q_DETAIL);
	const [fetchTemplates, { error: errorTemplate, data: dataTemplate }] = useLazyQuery(Q_TEMPLATES);
	const [addUser, { data: dataAddUser }] = useMutation(M_ADD_USER);
	const [removeUser, { data: dataRemoveUser, loading: loadingRemoveUser }] = useMutation(M_REMOVE_USER);
	const [breadcrumb, setBreadcrumb] = useState([]);

	useEffect(() => id && fetchDetail({ variables: { statementId: id } }), [id]);

	useEffect(() => detail && fetchTemplates({ variables: { report } }), [detail]);

	useEffect(() => dataDetail?.getEarlyWarningStatementById && setDetail(dataDetail?.getEarlyWarningStatementById), [dataDetail]);

	useEffect(() => dataTemplate?.getEarlyWarningTemplates && manipulateTemplates(dataTemplate?.getEarlyWarningTemplates), [dataTemplate]);

	useEffect(() => dataAddUser?.addUserToEarlyWarningStatement && fetchDetail({ variables: { statementId: id } }), [dataAddUser]);

	useEffect(() => {
		if (detail) {
			setMonth(parseInt(detail?.getEarlyWarningStatementById?.month) + 1);
			setYear(parseInt(detail?.getEarlyWarningStatementById?.year));
		}
	}, [detail]);

	useEffect(() => {
		if (dataRemoveUser?.removeUserFromEarlyWarningStatement) {
			fetchDetail({ variables: { statementId: id } });
			removeAssigneeModal?.onClose();
		}
	}, [dataRemoveUser]);

	useEffect(() => {
		if (errorDetail) {
			if (errorDetail?.graphQLErrors?.length > 0) {
				errorDetail?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorDetail?.message ? errorDetail?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		} else if (errorTemplate) {
			if (errorTemplate?.graphQLErrors?.length > 0) {
				errorTemplate?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorTemplate?.message ? errorTemplate?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorDetail, errorTemplate]);

	useEffect(() => {
		if (period && reportId) {
			setBreadcrumb([
				{
					name: 'Company Report',
					path: '/company-submission-report',
				},
				{
					name: period,
					path: `/early-warning-system?period=${period}&reportId=${reportId}&page=1&report=${report}`,
				},
				{
					name: c.reportNames[reportId],
					path: `/early-warning-system?period=${period}&reportId=${reportId}&page=1&report=${report}`,
				},
				{
					name: 'Detail',
					path: '#',
				},
			]);
		} else {
			setBreadcrumb([
				{
					name: 'Company Report',
					path: '/company-submission-report',
				},
				{
					name: 'Detail',
					path: '#',
				},
			]);
		}
	}, [period, reportId]);

	function manipulateTemplates(templates) {
		let code = null;
		let labels = [];
		let detailTemplate = null;
		let detailLabels = [];
		let readOnly = false;
		let localObjects = [];
		templates?.forEach((el) => {
			if (el?.code === EWSTypeTemplate.SFP) {
				code = el?.code;
				labels = el?.labels;
				detailTemplate = detail?.templates?.find((template) => template?.template?.code === code);
				detailLabels = detailTemplate?.labels;
				readOnly = true;
				localObjects.push({ ...el, cells: detailSFPCells({ code, labels, detailLabels, readOnly }) });
			} else if (el?.code === EWSTypeTemplate.SPLOCI) {
				code = el?.code;
				labels = el?.labels;
				detailTemplate = detail?.templates?.find((template) => template?.template?.code === code);
				detailLabels = detailTemplate?.labels;
				readOnly = true;
				localObjects.push({ ...el, cells: detailSPLOCICells({ code, labels, detailLabels, readOnly }) });
			} else if (el?.code === EWSTypeTemplate.SCF) {
				code = el?.code;
				labels = el?.labels;
				detailTemplate = detail?.templates?.find((template) => template?.template?.code === code);
				detailLabels = detailTemplate?.labels;
				readOnly = true;
				localObjects.push({ ...el, cells: detailSCFCells({ code, labels, detailLabels, readOnly }) });
			} else if (el?.code === EWSTypeTemplate.SOCE) {
				code = el?.code;
				labels = el?.labels;
				detailTemplate = detail?.templates?.find((template) => template?.template?.code === code);
				detailLabels = detailTemplate?.labels;
				readOnly = true;
				localObjects.push({ ...el, cells: detailSOCECells({ code, labels, detailLabels, readOnly }) });
			} else if (el?.code === EWSTypeTemplate.ORD) {
				code = el?.code;
				labels = el?.labels;
				detailTemplate = detail?.templates?.find((template) => template?.template?.code === code);
				detailLabels = detailTemplate?.labels;
				readOnly = true;
				localObjects.push({ ...el, cells: detailORDCells({ code, labels, detailLabels, readOnly }) });
			} else if (el?.code === EWSTypeTemplate.IR) {
				code = el?.code;
				labels = el?.labels;
				detailTemplate = detail?.templates?.find((template) => template?.template?.code === code);
				detailLabels = detailTemplate?.labels;
				readOnly = true;
				localObjects.push({ ...el, cells: detailIRCells({ code, labels, detailLabels, readOnly }) });
			}
		});
		setObjects(localObjects);
	}

	function cellChange({ code = null, cells = [] }) {
		setObjects(
			objects?.reduce((pre, cur, i) => {
				let localPre = pre;
				if (code === cur?.code) {
					localPre.push({ ...cur, cells });
				} else {
					localPre.push({ ...cur });
				}
				return localPre;
			}, [])
		);
	}

	function getDisplayName(inputUser) {
		let result = inputUser?.username;
		if (lang === 'kh') {
			if ((result = inputUser?.fullNameKhmer)) {
				result = inputUser?.fullNameKhmer;
			} else {
				if (inputUser?.fullName) {
					result = inputUser?.fullName;
				} else {
					result = inputUser?.username;
				}
			}
		} else {
			if ((result = inputUser?.fullName)) {
				result = inputUser?.fullName;
			} else {
				if (inputUser?.fullNameKhmer) {
					result = inputUser?.fullNameKhmer;
				} else {
					result = inputUser?.username;
				}
			}
		}
		return result;
	}

	function addUserToStatement(user) {
		addUser({ variables: { statementId: id, assigneeId: user?.id } });
	}

	function removeUserFromStatement(user) {
		removeUser({ variables: { statementId: id, assigneeId: user?.id } });
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent list={breadcrumb} />
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={4}
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Text fontSize="x-large" fontWeight="bold">
									{t(c.reportNames[reportId])}
								</Text>
								<Flex>
									<ExportExcelGeneral month={month} year={year} objects={objects} />
								</Flex>
							</Flex>
							{!(detail && objects) && (
								<>
									<Loading />
								</>
							)}
							{detail && objects && (
								<>
									<Flex pl={4} pr={4} pt={0} pb={0}>
										<SimpleGrid columns={[1, null, 2]} spacing={4} w="100%">
											<Flex
												w="100%"
												direction="column"
												border="1px dotted #dbdbdb"
												borderRadius={c.borderRadius}
												pl={4}
												pr={4}
												pt={4}
												pb={4}
											>
												<SpreadSheetKeyValuePair label="Company" value={<CompanyName company={detail?.company} />} />
												<SpreadSheetKeyValuePair label="Company Type" value={<CompanyBadge type={detail?.company?.type} />} />
												<SpreadSheetKeyValuePair
													label="Type"
													value={
														<Badge colorScheme="gray">
															{period === 'quarterly'
																? t(detail?.type)
																: report === 'ir'
																? t('Report on Investment')
																: detail?.type === 'AnnualAudited'
																? t('Audited Financial Statements')
																: detail?.type === 'AnnualNonAudited'
																? t('Unaudited Financial Statements')
																: t(detail?.type)}
														</Badge>
													}
												/>
												<SpreadSheetKeyValuePair
													label="Year"
													value={<LabelDate input={detail?.year} inputFormat="Y" outputFormat="YYYY" />}
												/>
												<SpreadSheetKeyValuePair
													label="Submitted At"
													value={<LabelDate input={detail?.createdAt} outputFormat={c.dateTimeFormat} />}
												/>
												{detail?.updatedAt && (
													<>
														<SpreadSheetKeyValuePair
															label="Updated at"
															value={<LabelDate input={detail?.updatedAt} outputFormat={c.dateTimeFormat} />}
														/>
													</>
												)}
												<SpreadSheetKeyValuePair
													label="Assign to"
													value={
														<Wrap>
															{detail?.assigns?.map((assign, i) => {
																return (
																	<Flex
																		key={i}
																		direction="column"
																		justifyContent="center"
																		alignItems="center"
																		maxWidth="114px"
																	>
																		<Tooltip
																			label={
																				<>
																					{t('Assign By')}&nbsp;
																					<UserDislayNameById id={assign?.createdBy} />
																					<LabelDate
																						input={assign?.createdAt}
																						outputFormat={c.longDateFormat}
																						color="white"
																					/>
																				</>
																			}
																		>
																			<WrapItem className="avatarWraper">
																				{(user?.role === 'admin' ||
																					(user?.ews?.includes('ewsCanAssignUser') &&
																						user?.id === assign?.createdBy)) && (
																					<FontAwesomeIcon
																						onMouseDown={() => {
																							removingAssignee = assign?.assignee;
																							removeAssigneeModal?.onOpen();
																						}}
																						className="remove"
																						icon={faMinusSquare}
																						style={{
																							fontSize: 18,
																							display: 'none',
																							position: 'absolute',
																							zIndex: '100',
																							marginLeft: 32,
																							color: 'red',
																							backgroundColor: 'white',
																							padding: 2,
																							cursor: 'pointer',
																						}}
																					/>
																				)}
																				<Avatar
																					name={getDisplayName(assign?.assignee)}
																					src={
																						assign?.assignee?.avatar?.icon
																							? c.baseFilePath + assign?.assignee?.avatar?.icon
																							: null
																					}
																				/>
																			</WrapItem>
																		</Tooltip>
																		<Tooltip label={getDisplayName(assign?.assignee)}>
																			<Text textAlign="center" noOfLines={1}>
																				{getDisplayName(assign?.assignee)}
																			</Text>
																		</Tooltip>
																	</Flex>
																);
															})}

															{(user?.role === 'admin' || user?.ews?.includes('ewsCanAssignUser')) && (
																<Flex>
																	<Button
																		mt={1}
																		className="hidden-print"
																		onMouseDown={addAssigneeModal?.onOpen}
																		leftIcon={<FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: 18 }} />}
																		colorScheme="blue"
																		variant="outline"
																	>
																		{t('Add')}
																	</Button>
																</Flex>
															)}
														</Wrap>
													}
												/>
											</Flex>

											<Flex
												w="100%"
												direction="column"
												border="1px dotted #dbdbdb"
												borderRadius={c.borderRadius}
												pl={4}
												pr={4}
												pt={4}
												pb={4}
											>
												{!detail?.isEditing && (
													<Center>
														<Button
															onMouseDown={editModal?.onOpen}
															isLoading={false}
															isDisabled={false}
															w="200px"
															onClick={() => null}
															leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
															colorScheme="gray"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
														>
															{t('Allow Editing')}
														</Button>
													</Center>
												)}

												{detail?.isEditing && (
													<Center>
														<Button
															onMouseDown={editModal?.onOpen}
															isLoading={false}
															isDisabled={false}
															w="200px"
															onClick={() => null}
															leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
															colorScheme="red"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
														>
															{t('Stop Allowing Editing')}
														</Button>
													</Center>
												)}

												<Flex direction="column" mt="20px">
													{detail?.isEditing && (
														<>
															<SpreadSheetKeyValuePair
																label="Allow Editing"
																value={<LabelDate input={detail?.editingUpdatedAt} outputFormat={c.dateTimeFormat} />}
															/>
															<SpreadSheetKeyValuePair label="By" value={<UserDislayNameById id={detail?.editingUpdatedBy} />} />
															<SpreadSheetKeyValuePair
																label="Due Date"
																value={<LabelDate input={detail?.editingDueDate} outputFormat={c.dateTimeFormat} />}
															/>
															<SpreadSheetKeyValuePair label="Description" value={detail?.editingDescription} />
														</>
													)}
												</Flex>
											</Flex>
										</SimpleGrid>
									</Flex>

									{detail?.attachmentFile && (detail?.type === EWSTypeStatement.aa || report === 'ir') && (
										<Box pl={4} pr={4} pb={0} pt={4}>
											<EWSAttachmentDetail attachmentFile={detail?.attachmentFile} />
										</Box>
									)}

									<Box pl={4} pr={4} pb={4} pt={4} overflow="clip">
										<Tabs>
											<TabList borderBottomWidth={objects ? 1 : 0} borderBottomStyle="solid" borderBottomColor="gray.200">
												{objects?.map((el, i) => (
													<Tooltip key={i} label={<>{lang === 'kh' ? el?.titleKhmer : el.titleEnglish}</>}>
														<Tab>
															<Text fontSize="md">{el?.code}</Text>
														</Tab>
													</Tooltip>
												))}
											</TabList>
											<TabPanels>
												{objects?.map((el, i) => (
													<TabPanel key={i} pl={0} pr={0} pt={6} pb={0}>
														{el?.code === EWSTypeTemplate.SFP && (
															<EWSSFP
																readOnly={true}
																code={EWSTypeTemplate.SFP}
																labels={el?.labels}
																cells={el?.cells}
																onChange={(result) => cellChange(result)}
															/>
														)}
														{el?.code === EWSTypeTemplate.SPLOCI && (
															<EWSSPLOCI
																readOnly={true}
																code={EWSTypeTemplate.SPLOCI}
																labels={el?.labels}
																cells={el?.cells}
																onChange={(result) => cellChange(result)}
															/>
														)}
														{el?.code === EWSTypeTemplate.SCF && (
															<EWSSCF
																readOnly={true}
																code={EWSTypeTemplate.SCF}
																labels={el?.labels}
																cells={el?.cells}
																onChange={(result) => cellChange(result)}
															/>
														)}
														{el?.code === EWSTypeTemplate.SOCE && (
															<EWSSOCE
																readOnly={true}
																code={EWSTypeTemplate.SOCE}
																labels={el?.labels}
																cells={el?.cells}
																onChange={(result) => cellChange(result)}
															/>
														)}
														{el?.code === EWSTypeTemplate.ORD && (
															<EWSORD
																readOnly={true}
																code={EWSTypeTemplate.ORD}
																labels={el?.labels}
																cells={el?.cells}
																onChange={(result) => cellChange(result)}
															/>
														)}
														{el?.code === EWSTypeTemplate.IR && (
															<EWSIR
																readOnly={true}
																code={EWSTypeTemplate.IR}
																labels={el?.labels}
																cells={el?.cells}
																onChange={(result) => cellChange(result)}
															/>
														)}
													</TabPanel>
												))}
											</TabPanels>
										</Tabs>
									</Box>
								</>
							)}
						</Box>
					</Center>
				</Container>
				<AlertDialog
					closeOnEsc={!loadingRemoveUser}
					closeOnOverlayClick={!loadingRemoveUser}
					isOpen={removeAssigneeModal?.isOpen}
					onClose={removeAssigneeModal?.onClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('Remove Assigned User')}
							</AlertDialogHeader>
							<AlertDialogBody>
								{t(`Are you sure you want to remove`)} "{getDisplayName(removingAssignee)}"?
							</AlertDialogBody>
							<AlertDialogFooter>
								<Button onClick={removeAssigneeModal?.onClose}>{t('Cancel')}</Button>
								<Button ml={2} isLoading={loadingRemoveUser} colorScheme="red" onClick={() => removeUserFromStatement(removingAssignee)}>
									{t('Remove')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
				{addAssigneeModal?.isOpen && (
					<SelectUserModal
						role="ews"
						callback={addUserToStatement}
						isOpen={addAssigneeModal?.isOpen}
						onClose={addAssigneeModal?.onClose}
						departments={user?.role !== 'admin' ? (user?.position > 1 ? user?.department : null) : null}
						offices={user?.position > 4 ? user?.office : null}
					/>
				)}
				{editModal?.isOpen && (
					<EWSStatementEditableModal
						id={id}
						isEditable={detail?.isEditing}
						editableDueDate={detail?.editingDueDate}
						editableDescription={detail?.editingDescription}
						onClose={editModal?.onClose}
						onCallback={() => fetchDetail({ variables: { statementId: id } })}
					/>
				)}
			</>
		</PrintLayout>
	);
}

export default EarlyWarningDetail;
