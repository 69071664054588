/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faPrint, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { dateRangeStringToDateRange, dateRangeToQueryString } from '../../../../utils';
import SaleSidebar from './SaleSidebar';
import SaleMarketShareGraph from './SaleMarketShareGraph';
import SaleInsuredPersonGenderGraph from './SaleInsuredPersonGenderGraph';
import SalePolicyGrossPremiumGraph from './SalePolicyGrossPremiumGraph';
import SaleGrossPremiumGrowthGraph from './SaleGrossPremiumGrowthGraph';
import Filter from '../../Miscellaneous/filter-dashboard';
import c from '../../../../constant';
import useStore from '../../../../store';
import useCompanyReportStore from '../../../../store/company-report-persistent-store';

function MonthlySaleDashboard() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const period = urlParams.get('period') || null;
	const reportId = urlParams.get('reportId') || null;
	const companyType = urlParams.get('companyType') || null;
	const companyId = urlParams.get('companyId') || null;
	const rangeOption = urlParams.get('rangeOption') || null;
	const rangeValue = urlParams.get('rangeValue') || null;
	const { push } = useHistory();
	const { t } = useTranslation();
	const { setCompanyType, setCompanyId } = useCompanyReportStore();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const [showReportButton, setShowReportButton] = useState(false);

	useEffect(() => {
		if (user) {
			const roles = user?.role.split(',') || [];
			const show = (roles?.includes('mdd') && user?.mdd?.includes('mddmsr')) || roles?.includes('admin');
			setShowReportButton(show);
		}
	}, [user]);

	function onApplyingFilter({ companyType = null, companyId = null, rangeOption = null, rangeValue = null }) {
		let url = '/market-development-data?period=' + period + '&reportId=' + reportId;

		if (companyType) {
			url = url + '&companyType=' + companyType;
		}

		if (companyId) {
			url = url + '&companyId=' + companyId;
		}

		if (rangeOption) {
			url = url + '&rangeOption=' + rangeOption;
		}

		if (rangeValue?.from && rangeValue?.to) {
			url = url + '&rangeValue=' + dateRangeToQueryString(rangeValue);
		}

		setCompanyType(companyType);

		setCompanyId(companyId);

		push(url);
	}

	return (
		<Box>
			<Flex justifyContent="center" alignItems="center" className="show-only-printing" borderBottomColor="gray.200" borderBottomWidth={1}>
				<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
					{t('Sales')}
				</Text>
			</Flex>
			<Flex mb={4} direction={['column', 'column', 'row']} justifyContent="flex-end" alignItems="center">
				{showReportButton && (
					<Button
						className="hidden-print"
						isLoading={false}
						isDisabled={false}
						leftIcon={<FontAwesomeIcon icon={faFileInvoice} style={{ fontSize: 16 }} />}
						size="sm"
						borderRadius={c.borderRadius}
						border="1px solid #bdc3c7"
						mr={[0, 0, 2]}
						width={['100%', '100%', 'auto']}
						colorScheme="gray"
						variant="solid"
						mt={[2, 2, 0]}
						onMouseDown={() => {
							push('/market-development-data/monthly-summarized-report');
						}}
					>
						{t('Report')}
					</Button>
				)}
				<Button
					className="hidden-print"
					isLoading={false}
					isDisabled={false}
					leftIcon={<FontAwesomeIcon icon={faList} style={{ fontSize: 16 }} />}
					size="sm"
					borderRadius={c.borderRadius}
					border="1px solid #bdc3c7"
					mr={[0, 0, 2]}
					width={['100%', '100%', 'auto']}
					colorScheme="gray"
					variant="solid"
					mt={[2, 2, 0]}
					onMouseDown={() => {
						let url = '/market-development-data/monthly-sale-report?period=' + period + '&reportId=' + reportId + '&page=1';
						if (companyType) {
							url = url + '&companyType=' + companyType;
						}
						if (companyId) {
							url = url + '&companyId=' + companyId;
						}
						push(url);
					}}
				>
					{t('List')}
				</Button>
				<Button
					className="hidden-print"
					isLoading={false}
					isDisabled={false}
					onClick={() => window?.print()}
					leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
					size="sm"
					borderRadius={c.borderRadius}
					border="1px solid #bdc3c7"
					width={['100%', '100%', 'auto']}
					colorScheme="gray"
					variant="solid"
					mt={[2, 2, 0]}
				>
					{t('Print')}
				</Button>
				<Filter
					companyType={companyType}
					companyId={companyId}
					rangeOption={rangeOption}
					rangeValue={dateRangeStringToDateRange(rangeValue)}
					onCallback={onApplyingFilter}
				/>
			</Flex>
			<Box display={['', '', 'flex']}>
				<Box width="100%" height="auto" mt={[4, 4, 0]}>
					<Box height="auto" mt={[0, 0, 0]} border="1px dotted #bdc3c7" pl={4} pr={4} pt={4} pb={4} mb={4}>
						<SaleSidebar companyType={companyType} companyId={companyId} range={dateRangeStringToDateRange(rangeValue)} />
					</Box>
					<SimpleGrid w="100%" columns={1} spacing={4}>
						<SaleMarketShareGraph companyType={companyType} companyId={companyId} range={dateRangeStringToDateRange(rangeValue)} />
						<SalePolicyGrossPremiumGraph companyType={companyType} companyId={companyId} range={dateRangeStringToDateRange(rangeValue)} />
						<SaleInsuredPersonGenderGraph companyType={companyType} companyId={companyId} range={dateRangeStringToDateRange(rangeValue)} />
						<SaleGrossPremiumGrowthGraph companyType={companyType} companyId={companyId} range={dateRangeStringToDateRange(rangeValue)} />
					</SimpleGrid>
				</Box>
			</Box>
		</Box>
	);
}

export default MonthlySaleDashboard;
