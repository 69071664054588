import React, { useEffect, useState } from "react";
import {
    Box, Flex, Modal, Image,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant";
import AgentReportPage from "../../containers/Settings/Agent/agent-statistic-report";

function AgentReport({
    isOpen = true, onClose = () => null, callback = () => null, url
}) {

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                }}
                size="6xl"
                scrollBehavior="outside"

            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pt="2">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pl={0} pr={0} pb={0} minH="80vh">
                        <AgentReportPage isModal={true} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default AgentReport;
