/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import {
	Badge,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import DataLoading from '../../../components/DataLoading';
import CompanyName from '../../../components/CompanyName';
import LocalizedDate from '../../../components/LocalizedDate';
import c from '../../../constant';
import useStore from '../../../store';

const QUERY_DATA = gql`
	query get($id: String!) {
		getCompanySaleProfessionalById(id: $id) {
			id
			idCardNumber
			firstName
			lastName
			firstNameEn
			lastNameEn
			gender
			dob
			company {
				id
				name
				nameKhmer
				logo
				type
			}
			intermediarySaleProfessional {
				id
				createdAt
				order
				deleted
				active
				intermediary {
					id
					name
					nameKhmer
					logo
					type
				}
				branch {
					id
					name
					nameLatin
				}
			}
			status
			baseCertificate
			professionalCertificate
			contract
			requestId
			terminationId
		}
	}
`;

function SaleStaffModal({ isOpen = false, onClose = () => null, id = null }) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state?.currentLang);
	const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);

	useEffect(() => id && loadData({ variables: { id } }), [id]);

	function getStatus(status, requestId, terminationId) {
		switch (status) {
			case 'active':
				return (
					<Link to={`/company-sale-professional/new/detail?id=${requestId}`}>
						<Badge colorScheme="green">{t('ACTIVE')}</Badge>
					</Link>
				);
			case 'terminated':
				return (
					<Link to={`/company-sale-professional/termination/detail?id=${terminationId}`}>
						<Badge colorScheme="red">{t('Terminated')}</Badge>
					</Link>
				);
			default:
				return null;
		}
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pt="2">
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody pl={0} pr={0} pb={0}>
						<DataLoading loading={loading} loadedData={data?.getCompanySaleProfessionalById}>
							{({ loadedData }) => (
								<TableContainer>
									<Table size="sm">
										<Thead>
											<Tr>
												<Th></Th>
												<Th isNumeric></Th>
											</Tr>
										</Thead>
										<Tbody>
											<Tr>
												<Td>{t('ID Card Number')}</Td>
												<Td isNumeric fontWeight="bold">
													{loadedData?.idCardNumber}
												</Td>
											</Tr>
											<Tr>
												<Td>{t('First Name')}</Td>
												<Td isNumeric fontWeight="bold" fontFamily="NotoSansKhmer">
													{loadedData?.firstName}
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Last Name')}</Td>
												<Td isNumeric fontWeight="bold" fontFamily="NotoSansKhmer">
													{loadedData?.lastName}
												</Td>
											</Tr>
											<Tr>
												<Td>{t('First Name En')}</Td>
												<Td isNumeric fontWeight="bold">
													{loadedData?.firstNameEn}
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Last Name En')}</Td>
												<Td isNumeric fontWeight="bold">
													{loadedData?.lastNameEn}
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Gender')}</Td>
												<Td isNumeric fontWeight="bold">
													{t(loadedData?.gender)}
												</Td>
											</Tr>
											<Tr>
												<Td>{t('DOB')}</Td>
												<Td isNumeric fontWeight="bold">
													<LocalizedDate dateString={loadedData?.dob} dateFormat={c.dateOnlyFormat} />
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Insurance Education Certificate')}</Td>
												<Td isNumeric fontWeight="bold">
													<Flex justifyContent="flex-end" alignItems="center">
														<DownloadAndViewButton
															marginLeft={0}
															url={loadedData?.baseCertificate?.url}
															size={loadedData?.baseCertificate?.size}
														/>
													</Flex>
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Specialized Certificate')}</Td>
												<Td isNumeric fontWeight="bold">
													<Flex justifyContent="flex-end" alignItems="center">
														<DownloadAndViewButton
															marginLeft={0}
															url={loadedData?.professionalCertificate?.url}
															size={loadedData?.professionalCertificate?.size}
														/>
													</Flex>
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Contract')}</Td>
												<Td isNumeric fontWeight="bold">
													<Flex justifyContent="flex-end" alignItems="center">
														<DownloadAndViewButton
															marginLeft={0}
															url={loadedData?.contract?.url}
															size={loadedData?.contract?.size}
														/>
													</Flex>
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Company')}</Td>
												<Td isNumeric fontWeight="bold">
													<Flex justifyContent="flex-end" alignItems="center">
														<CompanyName company={loadedData?.company} />
													</Flex>
												</Td>
											</Tr>
											<Tr>
												<Td>{t('Status')}</Td>
												<Td isNumeric fontWeight="bold">
													{getStatus(
														loadedData?.status === 'approved' ? 'active' : loadedData?.status,
														loadedData?.requestId,
														loadedData?.terminationId
													)}
												</Td>
											</Tr>
											{loadedData?.intermediarySaleProfessional?.intermediary && (
												<Tr>
													<Td>{t('Intermediary Company Name')}</Td>
													<Td isNumeric fontWeight="bold">
														<Flex justifyContent="flex-end" alignItems="center">
															<CompanyName company={loadedData?.intermediarySaleProfessional?.intermediary} />
														</Flex>
													</Td>
												</Tr>
											)}
											{loadedData?.intermediarySaleProfessional?.branch && (
												<Tr>
													<Td>{t('Branch')}</Td>
													<Td isNumeric fontWeight="bold">
														{lang === 'kh'
															? loadedData?.intermediarySaleProfessional?.branch?.name ||
															  loadedData?.intermediarySaleProfessional?.branch?.nameLatin
															: loadedData?.intermediarySaleProfessional?.branch?.nameLatin ||
															  loadedData?.intermediarySaleProfessional?.branch?.name}
													</Td>
												</Tr>
											)}
										</Tbody>
									</Table>
								</TableContainer>
							)}
						</DataLoading>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default SaleStaffModal;
