/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, forwardRef } from 'react';
import { Box, Flex, useToast, Text, Button, Tabs, TabList, TabPanel, TabPanels, Tab, useDisclosure, Divider, Badge, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { CompanyBadge } from '../../../Other';
import ExcelLife from '../Excel/ExcelLife';
import SpreadSheetTable from './table';
import SpreadSheetKeyValuePair from '../../Miscellaneous/spreadsheet-key-value-pair';
import UserDislayNameById from '../../../../components/UserDislayNameById';
import EditMonthlyReportModal from '../../../Modal/EditMonthlyReportModal';
import ContributionSale from '../../Miscellaneous/contribution-sale';
import Loading from '../../../Loading';
import CompanyName from '../../../CompanyName';
import LabelDate from '../../../LabelDate';
import c from '../../../../constant';
import useStore from '../../../../store';

const Q_DETAIL = gql`
	query get($id: String!) {
		getMonthlySaleReportLifeById(id: $id) {
			id
			reportType
			month
			year
			createdAt
			updatedAt
			company {
				id
				name
				nameKhmer
				logo
				type
			}
			isEditing
			editingUpdatedAt
			editingUpdatedBy
			editingDueDate
			editingDescription
			penalties {
				id
				active
			}
			contribution {
				id
				rate
				grossPremium
				attachmentFile
			}
			saleItems {
				id
				saleReportType
				saleProducts {
					productLine {
						id
						nameKh
						nameEn
						type
					}
					productType {
						id
						nameKh
						nameEn
					}
					businessType
					productValues {
						id
						saleProductId
						value
					}
				}
			}
		}
	}
`;

const Page = forwardRef(({ id = null }, ref) => {
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const [canAllowEditing, setCanAllowEditing] = useState(false);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [createdAt, setCreatedAt] = useState(null);
	const [updatedAt, setUpdatedAt] = useState(null);
	const [isPenalized, setPenalized] = useState(false);
	const [company, setCompany] = useState(null);
	const [saleItems, setSaleItems] = useState(null);
	const [getDetail, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(Q_DETAIL, { fetchPolicy: 'no-cache' });

	useEffect(() => {
		loadDetail();
	}, [id]);

	useEffect(() => {
		if (dataGet?.getMonthlySaleReportLifeById) {
			setMonth(parseInt(dataGet?.getMonthlySaleReportLifeById?.month) + 1);
			setYear(parseInt(dataGet?.getMonthlySaleReportLifeById?.year));
			setCreatedAt(dataGet?.getMonthlySaleReportLifeById?.createdAt);
			setUpdatedAt(dataGet?.getMonthlySaleReportLifeById?.updatedAt);
			setPenalized(dataGet?.getMonthlySaleReportLifeById?.penalties?.length > 0);
			setCompany(dataGet?.getMonthlySaleReportLifeById?.company);
			setSaleItems(dataGet?.getMonthlySaleReportLifeById?.saleItems);
		}
	}, [dataGet]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	useEffect(() => {
		const roles = user?.role.split(',') || [];
		const status = (roles?.includes('mdd') && user?.mdd?.includes('mddcae')) || roles?.includes('admin');
		setCanAllowEditing(status);
	}, [user]);

	function loadDetail() {
		id && getDetail({ variables: { id } });
	}

	return (
		<>
			{loadingGet ? (
				<>
					<Loading />
				</>
			) : (
				<>
					{month && year && createdAt && company && saleItems && (
						<>
							<Flex pl={4} pr={4} pt={0} pb={4}>
								<SimpleGrid columns={[1, null, 2]} w="100%" spacingX={4} spacingY={4}>
									<Flex flex={1} direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
										<>
											{isPenalized && (
												<Box>
													<Badge colorScheme="red" variant="solid">
														{t('Penalized')}
													</Badge>
												</Box>
											)}
										</>
										<SpreadSheetKeyValuePair label="Company" value={<CompanyName company={company} />} />
										<SpreadSheetKeyValuePair label="Type" value={<CompanyBadge type={company?.type} />} />
										<SpreadSheetKeyValuePair label="Monthly" value={<LabelDate input={month} inputFormat="M" outputFormat="MMMM" />} />
										<SpreadSheetKeyValuePair label="Year" value={<LabelDate input={year} inputFormat="Y" outputFormat="YYYY" />} />
										<SpreadSheetKeyValuePair label="Submitted At" value={<LabelDate input={createdAt} outputFormat={c.dateTimeFormat} />} />
										<>
											{updatedAt && (
												<>
													<SpreadSheetKeyValuePair
														label="Updated at"
														value={<LabelDate input={updatedAt} outputFormat={c.dateTimeFormat} />}
													/>
												</>
											)}
										</>
										<>
											{dataGet?.getMonthlySaleReportLifeById?.isEditing && (
												<>
													<Divider variant="dashed" mt={2} mb={2} />
													<SpreadSheetKeyValuePair
														label="Allow Editing"
														value={
															<LabelDate
																input={dataGet?.getMonthlySaleReportLifeById?.editingUpdatedAt}
																outputFormat={c.dateTimeFormat}
															/>
														}
													/>
													<SpreadSheetKeyValuePair
														label="By"
														value={<UserDislayNameById id={dataGet?.getMonthlySaleReportLifeById?.editingUpdatedBy} />}
													/>
													<SpreadSheetKeyValuePair
														label="Due Date"
														value={
															<LabelDate
																input={dataGet?.getMonthlySaleReportLifeById?.editingDueDate}
																outputFormat={c.dateTimeFormat}
															/>
														}
													/>
													<SpreadSheetKeyValuePair
														label="Description"
														value={dataGet?.getMonthlySaleReportLifeById?.editingDescription}
													/>
													{canAllowEditing && (
														<>
															<Box mt={2}>
																<Button
																	onMouseDown={onOpen}
																	isLoading={false}
																	isDisabled={false}
																	onClick={() => null}
																	leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
																	colorScheme="red"
																	variant="solid"
																	size="sm"
																	borderRadius={c.borderRadius}
																	border="1px solid #bdc3c7"
																	mr={2}
																>
																	{t('Stop Allowing Editing')}
																</Button>
															</Box>
														</>
													)}
													<Divider variant="dashed" mt={2} mb={2} />
												</>
											)}
										</>
										<Flex mt={2}>
											<>
												{!dataGet?.getMonthlySaleReportLifeById?.isEditing && canAllowEditing && (
													<Button
														onMouseDown={onOpen}
														isLoading={false}
														isDisabled={false}
														onClick={() => null}
														leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
														mr={2}
													>
														{t('Allow Editing')}
													</Button>
												)}
											</>
											<>
												<ExcelLife month={month} year={year} numColumns={24} saleItems={saleItems} />
											</>
										</Flex>
									</Flex>
									<Flex>
										<ContributionSale contribution={dataGet?.getMonthlySaleReportLifeById?.contribution} />
									</Flex>
								</SimpleGrid>
							</Flex>
							<Box pl={4} pr={4} pb={4} pt={4} overflow="clip">
								<Tabs>
									<TabList borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.200">
										{saleItems?.map((el) => {
											return (
												<Tab key={el?.id}>
													<Text>{t(el?.saleReportType)}</Text>
												</Tab>
											);
										})}
									</TabList>
									<TabPanels>
										{saleItems?.map((el) => {
											return (
												<TabPanel key={el?.id} pl={0} pr={0} pb={0}>
													<>
														<SpreadSheetTable saleProducts={el?.saleProducts} />
													</>
												</TabPanel>
											);
										})}
									</TabPanels>
								</Tabs>
							</Box>
						</>
					)}
				</>
			)}
			{isOpen && (
				<EditMonthlyReportModal
					id={id}
					type="Sale"
					isEditable={dataGet?.getMonthlySaleReportLifeById?.isEditing}
					editableDueDate={dataGet?.getMonthlySaleReportLifeById?.editingDueDate}
					editableDescription={dataGet?.getMonthlySaleReportLifeById?.editingDescription}
					onClose={onClose}
					onCallback={loadDetail}
				/>
			)}
		</>
	);
});

export default Page;
