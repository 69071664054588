/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { formatCurrency, formatNumber } from '../../../../utils/format';
import { toFixedTrunc } from '../../../../utils/fixed-trunc';
import DataLoading from '../../../DataLoading';
import moment from 'moment';

const Q_DATASET = gql`
	query get($type: MDDataSummaryReportEnumType!, $year: Float!, $month: Float!) {
		getMonthlySummaryReport(type: $type, year: $year, month: $month)
	}
`;

export default function SummarizedSpreadSheetTable({ type = null, year = null, month = null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [products, setProducts] = useState(null);
	const [localYear, setLocalYear] = useState(null);
	const [localMonth, setLocalMonth] = useState(null);
	const [getDataset, { loading, data, error }] = useLazyQuery(Q_DATASET, { fetchPolicy: 'no-cache' });

	useEffect(() => {
		setLocalYear(year ? parseInt(year) : moment().year());
		setLocalMonth(month ? parseInt(month) : moment().month());
	}, [month, year]);

	useEffect(() => {
		if (data?.getMonthlySummaryReport) {
			setProducts(data?.getMonthlySummaryReport?.duringByProduct.products);
		}
	}, [data]);

	const load = useCallback(() => {
		getDataset({
			variables: {
				type,
				year: localYear,
				month: localMonth,
			},
		});
	}, [getDataset, type, localYear, localMonth]);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Flex justifyContent="flex-start" alignItems="center" ml={4} mr={4}>
				<Text fontSize={'15px'} fontWeight={'bold'}>
					{`${t('1. Insurance Market Data as of')} ${t(moment(localMonth + 1, 'MM').format('MMMM'))} ${t(localYear)}`}
				</Text>
			</Flex>
			<DataLoading loading={loading} loadedData={data?.getMonthlySummaryReport?.asOf}>
				{({ loadedData }) => (
					<Box ml={4} mr={4} mt={0} mb={8} overflow="scroll">
						<table className="spreadsheet_custom_header" style={{ marginTop: '10px', width: '40%' }}>
							<thead style={{ height: '35px' }}>
								<tr>
									<th></th>
									<td>{`${t('as of')} ${t(moment(localMonth + 1, 'MM').format('MMMM'))}`}</td>
								</tr>
							</thead>
							<tbody>
								{/* Gross Premium */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Gross Premium')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.grosspremium, 3))}</th>
								</tr>
								{/* Number of Policies */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Number of Policies')}</td>
									<th>{formatNumber(toFixedTrunc(loadedData?.numpolicies, 3))}</th>
								</tr>
								{/* Sum Insured */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Sum Insured')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.suminsured, 3))}</th>
								</tr>
								{/* Gross Claim incurred */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Gross Claim Incurred')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.claimincurred, 3))}</th>
								</tr>
								{/* Gross Claim Paid */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Gross Claim Paid')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.claimpaid, 3))}</th>
								</tr>
							</tbody>
						</table>
					</Box>
				)}
			</DataLoading>

			<Flex justifyContent="flex-start" alignItems="center" ml={4} mr={4}>
				<Text fontSize={'15px'} fontWeight={'bold'}>
					{`${t('2. Insurance Market Data in')} ${t(moment(localMonth + 1, 'MM').format('MMMM'))} ${t(localYear)}`}
				</Text>
			</Flex>
			<DataLoading loading={loading} loadedData={data?.getMonthlySummaryReport?.during}>
				{({ loadedData }) => (
					<Box ml={4} mr={4} mt={0} mb={8} overflow="scroll">
						<table className="spreadsheet_custom_header" style={{ marginTop: '10px', width: '60%' }}>
							<thead style={{ height: '35px' }}>
								<tr>
									<th></th>
									<td>{localYear - 1}</td>
									<td>{localYear}</td>
								</tr>
							</thead>
							<tbody>
								{/* Premium */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Gross Premium')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.grosspremium?.[localYear - 1], 3))}</th>
									<th>{formatCurrency(toFixedTrunc(loadedData?.grosspremium?.[localYear], 3))}</th>
								</tr>
								{/* Number of Policies */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Number of Policies')}</td>
									<th>{formatNumber(toFixedTrunc(loadedData?.numpolicies?.[localYear - 1], 3))}</th>
									<th>{formatNumber(toFixedTrunc(loadedData?.numpolicies?.[localYear], 3))}</th>
								</tr>
								{/* Sum Insured */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Sum Insured')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.suminsured?.[localYear - 1], 3))}</th>
									<th>{formatCurrency(toFixedTrunc(loadedData?.suminsured?.[localYear], 3))}</th>
								</tr>
								{/* Gross Claim incurred */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Gross Claim Incurred')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.claimincurred?.[localYear - 1], 3))}</th>
									<th>{formatCurrency(toFixedTrunc(loadedData?.claimincurred?.[localYear], 3))}</th>
								</tr>
								{/* Gross Claim Paid */}
								<tr height={'35px'}>
									<td style={{ minWidth: '160px' }}>{t('Gross Claim Paid')}</td>
									<th>{formatCurrency(toFixedTrunc(loadedData?.claimpaid?.[localYear - 1], 3))}</th>
									<th>{formatCurrency(toFixedTrunc(loadedData?.claimpaid?.[localYear], 3))}</th>
								</tr>
							</tbody>
						</table>
					</Box>
				)}
			</DataLoading>
			<Flex justifyContent="flex-start" alignItems="center" ml={4} mr={4}>
				<Text fontSize={'15px'} fontWeight={'bold'}>
					{`${t('3. Type of Insurance Product by')} ${t(moment(localMonth + 1, 'MM').format('MMMM'))} ${t(localYear)}`}
				</Text>
			</Flex>
			<DataLoading loading={loading} loadedData={data?.getMonthlySummaryReport?.duringByProduct}>
				{({ loadedData }) => (
					<Box ml={4} mr={4} mt={0} mb={0} overflow="scroll">
						<table className="spreadsheet_custom_header_report" style={{ marginTop: '10px' }}>
							<thead>
								<tr>
									<th></th>
									<td>{t('Year')}</td>
									{products?.map((el) => {
										return <td>{el?.productNameKh}</td>;
									})}
								</tr>
							</thead>
							<tbody>
								{/* Premium */}
								<tr height={'35px'}>
									<td rowSpan={2} style={{ minWidth: '200px', left: '20px' }}>
										{t('Gross Premium')}
									</td>
									<th>{localYear - 1}</th>
									{loadedData?.grosspremium[localYear - 1]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.grosspremium[localYear]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Number of Policies */}
								<tr height={'35px'}>
									<td rowSpan={2} style={{ minWidth: '200px', position: 'sticky' }}>
										{t('Number of Policies')}
									</td>
									<th>{localYear - 1}</th>
									{loadedData?.numpolicies[localYear - 1]?.map((el) => {
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.numpolicies[localYear]?.map((el) => {
										return <th>{formatNumber(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Sum Insured */}
								<tr height={'35px'}>
									<td rowSpan={2} style={{ minWidth: '200px' }}>
										{t('Sum Insured')}
									</td>
									<th>{localYear - 1}</th>
									{loadedData?.suminsured[localYear - 1]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.suminsured[localYear]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Gross Claim incurred */}
								<tr height={'35px'}>
									<td rowSpan={2} style={{ minWidth: '200px', position: 'sticky' }}>
										{t('Gross Claim Incurred')}
									</td>
									<th>{localYear - 1}</th>
									{loadedData?.claimincurred[localYear - 1]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.claimincurred[localYear]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								{/* Gross Claim Paid */}
								<tr height={'35px'} style={{ minWidth: '200px', position: 'sticky' }}>
									<td rowSpan={2}>{t('Gross Claim Paid')}</td>
									<th>{localYear - 1}</th>
									{loadedData?.claimpaid[localYear - 1]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
								<tr height={'35px'}>
									<th>{localYear}</th>
									{loadedData?.claimpaid[localYear]?.map((el) => {
										return <th>{formatCurrency(toFixedTrunc(el, 3))}</th>;
									})}
								</tr>
							</tbody>
						</table>
					</Box>
				)}
			</DataLoading>
		</>
	);
}
