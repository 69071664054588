import React, { useEffect } from 'react';
import { Container, Box, Flex, Text, Button, Stat, StatLabel, StatNumber, SimpleGrid, StatGroup, Center, Divider } from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../../constant';
import DateRangePicker from '@cloudscape-design/components/date-range-picker';
import moment from 'moment';
import _ from 'lodash';

const labelW = '200px';
const QUERY_DATA = gql`
	query get($toDate: DateTime, $fromDate: DateTime) {
		agentReportByDate(startDate: $fromDate, endDate: $toDate) {
			totalInsuranceRegistration
			countRejectRegistration
			countApprovedRegistration
			countActiveRegistration
			countReviewingRegistration
			countExpiredRegistration
			totalRenewalSubmitted
			countApprovedRenewal
			countRejectedRenewal
			coundPaidRenewal
		}
	}
`;

export default function AgentStatisticReport({isModal}) {
	const { t } = useTranslation();
	const [pickedDateRange, setPickedDateRange] = React.useState(undefined);
	const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		if (pickedDateRange) {
			loadData({
				variables: {
					fromDate: moment(pickedDateRange?.startDate).toDate(),
					toDate: moment(pickedDateRange?.endDate).toDate(),
				},
			});
		} else {
			loadData();
		}
	}, [pickedDateRange]);

	function getHeader() {
		return (
			<Flex className="table-header" direction={'row'} alignItems={'center'}>
				<Flex flexGrow={1} className="title"></Flex>
				<Flex direction={'row'}>
					<FilterByDateRange />
					{!isModal&&<Button
						ml="10px"
						onClick={() => window?.print()}
						leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
						colorScheme="gray"
						variant="solid"
						size="sm"
						borderRadius={c.borderRadius}
						border="1px solid #bdc3c7"
					>
						{t('Print')}
					</Button>}
				</Flex>
			</Flex>
		);
	}
	// end table config

	function FilterByDateRange() {
		console.log('pickedDateRange:', pickedDateRange);
		return (
			<DateRangePicker
				onChange={({ detail }) => setPickedDateRange(detail.value)}
				value={pickedDateRange}
				rangeSelectorMode="absolute-only"
				placeholder="Filter by a date and time range"
				i18nStrings={{ applyButtonLabel: 'Apply', clearButtonLabel: 'Clear' }}
				absoluteFormat="long-localized"
				showClearButton={true}
				dateOnly={true}
			/>
		);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				{!isModal&&<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'Agent Statistic Report',
							path: '#',
						},
					]}
				/>}

				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Box p="4" w="100%" maxW={'8xl'} mt="5">
							{getHeader()}
							{loading && <Text>{t('Loading')}</Text>}
							{!loading&&<>
							{data?.agentReportByDate && (
								<StatGroup mt="4">
									<SimpleGrid w="100%" columns={[2, null, 3]} spacing="40px">
										<Stat>
											<StatLabel>Total Insurance Agent Registration</StatLabel>
											<StatNumber>{data?.agentReportByDate?.totalInsuranceRegistration}</StatNumber>
										</Stat>

										<Stat>
											<StatLabel>Approved Registration</StatLabel>
											<StatNumber>{data?.agentReportByDate?.countApprovedRegistration}</StatNumber>
										</Stat>

										<Stat>
											<StatLabel>Rejected Registration</StatLabel>
											<StatNumber>{data?.agentReportByDate?.countRejectRegistration}</StatNumber>
										</Stat>

										<Stat>
											<StatLabel>Agent Card Proveded (New Code)</StatLabel>
											<StatNumber>{data?.agentReportByDate?.countActiveRegistration}</StatNumber>
										</Stat>

										<Stat>
											<StatLabel>Expired Card</StatLabel>
											<StatNumber>{data?.agentReportByDate?.countExpiredRegistration}</StatNumber>
										</Stat>
									</SimpleGrid>
								</StatGroup>
							)}
							<Divider />
							{data?.agentReportByDate && (
								<StatGroup mt="4">
									<SimpleGrid w="100%" columns={[2, null, 3]} spacing="40px">
										<Stat>
											<StatLabel>Total Renewal Submitted</StatLabel>
											<StatNumber>{data?.agentReportByDate?.totalRenewalSubmitted}</StatNumber>
										</Stat>

										<Stat>
											<StatLabel>Approved Renewal</StatLabel>
											<StatNumber>{data?.agentReportByDate?.countApprovedRenewal}</StatNumber>
										</Stat>

										<Stat>
											<StatLabel>Paid Renewal</StatLabel>
											<StatNumber>{data?.agentReportByDate?.coundPaidRenewal}</StatNumber>
										</Stat>

										<Stat>
											<StatLabel>Rejected Renewal</StatLabel>
											<StatNumber>{data?.agentReportByDate?.countRejectedRenewal}</StatNumber>
										</Stat>
									</SimpleGrid>
								</StatGroup>
							)}
							</>}
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}
