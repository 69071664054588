/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Badge, Box, Button, Center, Container, Flex, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight, faPlus, faPrint, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanyName from '../../../components/CompanyName';
import LocalizedDate from '../../../components/LocalizedDate';
import PrintLayout from '../../../components/Print/PrintLayout';
import TotalRow from '../../../components/TotalRow';
import ListLoading from '../../../components/ListLoading';
import IntermediaryTab from '../../ManageAgentPartner/Intermediary/components/IntermediaryTab';
import SaleStaffModal from '../component/SaleStaffModal';
import datePickerFromToToDate from '../../../utils/datePickerFromToToDate';
import SaleStaffFilter from '../component/FilterSaleStaff';
import c from '../../../constant';

const QUERY_DATA = gql`
	query get(
		$offset: Float
		$limit: Float
		$status: String
		$number: String
		$firstName: String
		$lastName: String
		$firstNameEn: String
		$lastNameEn: String
		$gender: String
		$dobFrom: DateTime
		$dobTo: DateTime
		$companyId: String
		$companyName: String
	) {
		getCompanySaleProfessionalList(
			pagerInput: { limit: $limit, offset: $offset }
			status: $status
			number: $number
			firstName: $firstName
			lastName: $lastName
			firstNameEn: $firstNameEn
			lastNameEn: $lastNameEn
			gender: $gender
			dobFrom: $dobFrom
			dobTo: $dobTo
			companyId: $companyId
			companyName: $companyName
		) {
			totalRows
			totalPages
			list {
				id
				idCardNumber
				firstName
				lastName
				firstNameEn
				lastNameEn
				gender
				dob
				company {
					id
					name
					nameKhmer
					logo
					type
				}
				status
			}
		}
	}
`;

function List() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const page = urlParams.get('page') ? urlParams.get('page') : 1;
	const status = urlParams.get('status') ? urlParams.get('status') : 'approved';
	const number = urlParams.get('number') || null;
	const firstName = urlParams.get('firstName') || null;
	const lastName = urlParams.get('lastName') || null;
	const firstNameEn = urlParams.get('firstNameEn') || null;
	const lastNameEn = urlParams.get('lastNameEn') || null;
	const gender = urlParams.get('gender') || null;
	const dobFrom = urlParams.get('dobFrom') || null;
	const dobTo = urlParams.get('dobTo') || null;
	const companyName = urlParams.get('companyName') || null;
	const companyId = urlParams.get('companyId') || null;
	const { t } = useTranslation();
	const { push } = useHistory();
	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);
	const [openId, setOpenId] = useState(null);

	useEffect(() => data?.getCompanySaleProfessionalList && setTimeout(() => scrollToTop(), 500), [data]);

	useEffect(() => {
		const offset = parseInt(page ? (page - 1) * 10 : 0) || 0;
		const limit = 10;
		const variables = {
			offset,
			limit,
			status,
			number,
			firstName,
			lastName,
			firstNameEn,
			lastNameEn,
			gender,
			dobFrom: datePickerFromToToDate(dobFrom),
			dobTo: datePickerFromToToDate(dobTo),
			companyId,
			companyName,
		};
		loadData({ variables });
	}, [status, page, number, firstName, lastName, firstNameEn, lastNameEn, gender, dobFrom, dobTo, companyId, companyName]);

	function handleStatusChanged(statusData) {
		let url = `?page=1`;

		if (statusData) {
			url += `&status=${statusData}`;
		}

		push(`/company-sale-professional/list${url}`);
	}

	function getStatus(status) {
		switch (status) {
			case 'active':
				return <Badge colorScheme="green">{t('ACTIVE')}</Badge>;
			case 'terminated':
				return <Badge colorScheme="red">{t('Terminated')}</Badge>;
			default:
				return null;
		}
	}

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;

		if (status) {
			url += `&status=${status}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (firstName) {
			url += `&firstName=${firstName}`;
		}
		if (lastName) {
			url += `&lastName=${lastName}`;
		}
		if (firstNameEn) {
			url += `&firstNameEn=${firstNameEn}`;
		}
		if (lastNameEn) {
			url += `&lastNameEn=${lastNameEn}`;
		}
		if (gender) {
			url += `&gender=${gender}`;
		}

		if (dobFrom && dobTo) {
			url += `&dobFrom=${dobFrom}&dobTo=${dobTo}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		push(`/company-sale-professional/list${url}`);
	}

	function scrollToTop() {
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Company Sale Staff',
								path: '/company-sale-professional/menu',
							},
							{
								name: 'List',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t('Insurance Sale Staff')}
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex className="panel-tab-container" minW="303px">
									<IntermediaryTab
										onCallback={(e) => handleStatusChanged(e)}
										initTab="approved"
										data={[
											{
												label: 'Active',
												badge: null,
												value: 'approved',
												important: '',
											},
											{
												label: 'Terminated',
												badge: null,
												value: 'terminated',
												important: '',
											},
											{
												label: 'All',
												badge: null,
												value: 'all',
												important: '',
											},
										]}
									/>
								</Flex>
								<Flex alignItems="center">
									<Button
										mr="2"
										onMouseDown={() => push('/company-sale-professional/list/add')}
										leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Add')}
									</Button>
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Button
										onMouseDown={() => setIsOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={
											number ||
											firstName ||
											lastName ||
											firstNameEn ||
											lastNameEn ||
											gender ||
											(dobFrom && dobTo) ||
											companyName ||
											companyId
												? 'yellow'
												: 'gray'
										}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<ListLoading
								loading={loading}
								loadedList={data?.getCompanySaleProfessionalList?.list}
								totalRows={data?.getCompanySaleProfessionalList?.totalRows}
								totalPages={data?.getCompanySaleProfessionalList?.totalPages}
							>
								{({ loadedList, totalPages, totalRows }) => (
									<Box p="16px">
										<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
											<Table variant="simple" size="sm">
												<Thead>
													<Tr>
														<Th>{t('ID Card Number')}</Th>
														<Th>{t('First Name')}</Th>
														<Th>{t('Last Name')}</Th>
														<Th>{t('First Name En')}</Th>
														<Th>{t('Last Name En')}</Th>
														<Th>{t('Gender')}</Th>
														<Th>{t('DOB')}</Th>
														<Th>{t('Company')}</Th>
														<Th isNumeric>{t('Status')}</Th>
													</Tr>
												</Thead>
												<Tbody>
													{loadedList?.map((item, index) => (
														<Tr key={index} cursor="pointer" _hover={{ bg: 'gray.100' }} onMouseDown={() => setOpenId(item?.id)}>
															<Td>{item?.idCardNumber}</Td>
															<Td fontFamily="NotoSansKhmer">{item?.firstName}</Td>
															<Td fontFamily="NotoSansKhmer">{item?.lastName}</Td>
															<Td>{item?.firstNameEn}</Td>
															<Td>{item?.lastNameEn}</Td>
															<Td>{t(item?.gender)}</Td>
															<Td>
																<LocalizedDate dateString={item?.dob} dateFormat={c.dateOnlyFormat} />
															</Td>
															<Td>
																<Text noOfLines={1}>
																	<CompanyName company={item?.company} />
																</Text>
															</Td>
															<Td isNumeric>{getStatus(item?.status === 'approved' ? 'active' : item?.status)}</Td>
														</Tr>
													))}
												</Tbody>
												<Tfoot>
													<Tr>
														<Th>{t('ID Card Number')}</Th>
														<Th>{t('First Name')}</Th>
														<Th>{t('Last Name')}</Th>
														<Th>{t('First Name En')}</Th>
														<Th>{t('Last Name En')}</Th>
														<Th>{t('Gender')}</Th>
														<Th>{t('DOB')}</Th>
														<Th>{t('Company')}</Th>
														<Th isNumeric>{t('Status')}</Th>
													</Tr>
												</Tfoot>
											</Table>
										</TableContainer>
										{totalRows > 0 && (
											<Flex justifyContent="flex-end" direction="row">
												<TotalRow count={totalRows} mt={2} />
											</Flex>
										)}
										{totalPages > 1 && (
											<Flex alignItems="flex-end" direction="column" mt={4}>
												<Flex alignItems="flex-end" direction="column">
													<ReactPaginate
														previousLabel={<FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 12, cursor: 'pointer' }} />}
														nextLabel={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 12, cursor: 'pointer' }} />}
														breakLabel={'...'}
														breakClassName={'break-me'}
														disableInitialCallback={true}
														pageCount={totalPages}
														marginPagesDisplayed={2}
														pageRangeDisplayed={3}
														onPageChange={handlePageChanged}
														containerClassName={'pagination'}
														activeClassName={'active'}
														initialPage={(page - 1) | 0}
													/>
												</Flex>
											</Flex>
										)}
									</Box>
								)}
							</ListLoading>
						</Box>
					</Center>
				</Container>
				{openId && <SaleStaffModal isOpen={true} onClose={() => setOpenId(false)} id={openId} />}
				{isOpenFilter && (
					<SaleStaffFilter
						isOpen={true}
						onClose={() => setIsOpenFilter(false)}
						onCallback={(result) => {
							let url = '';

							if (status) {
								url += `&status=${status}`;
							}

							if (result?.number) {
								url += `&number=${result?.number}`;
							}

							if (result?.firstName) {
								url += `&firstName=${result?.firstName}`;
							}
							if (result?.lastName) {
								url += `&lastName=${result?.lastName}`;
							}
							if (result?.firstNameEn) {
								url += `&firstNameEn=${result?.firstNameEn}`;
							}
							if (result?.lastNameEn) {
								url += `&lastNameEn=${result?.lastNameEn}`;
							}
							if (result?.gender) {
								url += `&gender=${result?.gender}`;
							}

							if (result?.dobFrom && result?.dobTo) {
								url += `&dobFrom=${result?.dobFrom}&dobTo=${result?.dobTo}`;
							}

							if (result?.companyName) {
								url += `&companyName=${result?.companyName}`;
							}

							if (result?.companyId) {
								url += `&companyId=${result?.companyId}`;
							}

							push(`/company-sale-professional/list?page=1${url}`);
						}}
						number={number}
						firstName={firstName}
						lastName={lastName}
						firstNameEn={firstNameEn}
						lastNameEn={lastNameEn}
						gender={gender}
						dobFrom={dobFrom}
						dobTo={dobTo}
						companyName={companyName}
						companyId={companyId}
					/>
				)}
			</>
		</PrintLayout>
	);
}

export default List;
