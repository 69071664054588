/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, forwardRef } from 'react';
import { Box, Flex, useToast, Button, useDisclosure, Badge, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { CompanyBadge } from '../../../Other';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import ExcelGeneral from '../Excel/ExcelGeneral';
import SpreadSheetTable from './table';
// mtpl import SpreadSheetTableMtpl from '../../../../containers/MarketDevelopment/Components/mtpl-table';
import SpreadSheetKeyValuePair from '../../Miscellaneous/spreadsheet-key-value-pair';
import UserDislayNameById from '../../../../components/UserDislayNameById';
import EditMonthlyReportModal from '../../../Modal/EditMonthlyReportModal';
import Loading from '../../../Loading';
import CompanyName from '../../../CompanyName';
import LabelDate from '../../../LabelDate';
import c from '../../../../constant';
import useStore from '../../../../store';

const Q_DETAIL = gql`
	query get($id: String!) {
		getMonthlyClaimReportById(id: $id) {
			id
			reportType
			month
			year
			createdAt
			updatedAt
			company {
				id
				name
				nameKhmer
				logo
				type
			}
			isEditing
			editingUpdatedAt
			editingUpdatedBy
			editingDueDate
			editingDescription
			penalties {
				id
				active
			}
			claimItems {
				claimProducts {
					productLine {
						id
						nameKh
						nameEn
						type
					}
					productValues {
						id
						claimType
						claimValueType
						value
					}
				}
			}
			mtplItems {
				id
				reportId
				template {
					id
					titleKhmer
					titleEnglish
					code
				}
				labels {
					id
					templateLabel {
						id
						titleKhmer
						titleEnglish
						code
					}
					values {
						id
						value
						province {
							id
							name
							latinName
							code
						}
					}
				}
			}
		}
	}
`;

// mtpl
// const Q_TEMPLATES = gql`
// 	query get($code: String!) {
// 		getTemplateByCode(code: $code) {
// 			id
// 			titleKhmer
// 			titleEnglish
// 			code
// 			order
// 			labels {
// 				id
// 				code
// 				titleKhmer
// 				titleEnglish
// 				level
// 				order
// 				readOnly
// 			}
// 			provinces {
// 				id
// 				code
// 				name
// 				latinName
// 			}
// 		}
// 	}
// `;

const Page = forwardRef(({ id = null }, ref) => {
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const [canAllowEditing, setCanAllowEditing] = useState(false);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [createdAt, setCreatedAt] = useState(null);
	const [updatedAt, setUpdatedAt] = useState(null);
	const [isPenalized, setPenalized] = useState(false);
	const [company, setCompany] = useState(null);
	const [claimItem, setClaimItem] = useState(null);
	// mtpl const [object, setObject] = useState(null);
	const [getDetail, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(Q_DETAIL);
	// mtpl const [getTemplates, { data: dataTemplate, error: errorTemplate }] = useLazyQuery(Q_TEMPLATES);

	useEffect(loadDetail, [id]);

	// mtpl useEffect(loadTemplates, [dataGet]);

	useEffect(() => {
		if (dataGet?.getMonthlyClaimReportById) {
			setMonth(parseInt(dataGet?.getMonthlyClaimReportById?.month) + 1);
			setYear(parseInt(dataGet?.getMonthlyClaimReportById?.year));
			setCreatedAt(dataGet?.getMonthlyClaimReportById?.createdAt);
			setUpdatedAt(dataGet?.getMonthlyClaimReportById?.updatedAt);
			setPenalized(dataGet?.getMonthlyClaimReportById?.penalties?.length > 0);
			setCompany(dataGet?.getMonthlyClaimReportById?.company);
			setClaimItem(dataGet?.getMonthlyClaimReportById?.claimItems[0]);
		}
	}, [dataGet]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	// mtpl
	// useEffect(() => {
	// 	if (errorTemplate) {
	// 		if (errorTemplate?.graphQLErrors?.length > 0) {
	// 			errorTemplate?.graphQLErrors.map(({ message }, i) =>
	// 				toast({
	// 					title: t(message),
	// 					status: 'error',
	// 					isClosable: true,
	// 					position: 'bottom-right',
	// 				})
	// 			);
	// 		} else {
	// 			toast({
	// 				title: t(errorTemplate?.message ? errorTemplate?.message : 'something went wrong'),
	// 				status: 'error',
	// 				isClosable: true,
	// 				position: 'bottom-right',
	// 			});
	// 		}
	// 	}
	// }, [errorTemplate]);

	// mtpl
	// useEffect(() => {
	// 	if (dataTemplate?.getTemplateByCode) {
	// 		const object = dataTemplate?.getTemplateByCode;
	// 		const labels = object?.labels;
	// 		const provinces = object?.provinces?.filter((el) => el?.code !== '9999').sort((a, b) => a?.code < b?.code);
	// 		setObject({
	// 			...object,
	// 			cells: labels?.map((label, i) => {
	// 				if (label?.readOnly) {
	// 					return provinces?.map((el) => ({ value: '', readOnly: true }));
	// 				} else {
	// 					const localTemplate = dataGet?.getMonthlyClaimReportById?.mtplItems?.find((el) => el?.template?.code === c.mtplGClaim);
	// 					const localLabels = localTemplate?.labels;
	// 					const localLabel = localLabels?.find((el) => el?.templateLabel?.code === label?.code);
	// 					const localValues = localLabel?.values?.sort((a, b) => a?.province?.code < b?.province?.code);
	// 					return localValues?.map((el) => ({ value: el?.value, readOnly: true }));
	// 				}
	// 			}),
	// 			provinces,
	// 		});
	// 	}
	// }, [dataTemplate]);

	useEffect(() => {
		const roles = user?.role.split(',') || [];
		const status = (roles?.includes('mdd') && user?.mdd?.includes('mddcae')) || roles?.includes('admin');
		setCanAllowEditing(status);
	}, [user]);

	function loadDetail() {
		id && getDetail({ variables: { id } });
	}

	// mtpl
	// function loadTemplates() {
	// 	dataGet?.getMonthlyClaimReportById && getTemplates({ variables: { code: c.mtplGClaim } });
	// }

	return (
		<>
			{loadingGet ? (
				<>
					<Loading />
				</>
			) : (
				<>
					{month && year && createdAt && company && claimItem && (
						<>
							<Flex pl={4} pr={4}>
								<SimpleGrid columns={[1, null, dataGet?.getMonthlyClaimReportById?.isEditing ? 2 : 1]} w="100%" spacingX={4} spacingY={4}>
									<Flex w="100%" direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
										<>
											{isPenalized && (
												<Box>
													<Badge colorScheme="red" variant="solid">
														{t('Penalized')}
													</Badge>
												</Box>
											)}
										</>
										<SpreadSheetKeyValuePair label="Company" value={<CompanyName company={company} />} />
										<SpreadSheetKeyValuePair label="Type" value={<CompanyBadge type={company?.type} />} />
										<SpreadSheetKeyValuePair label="Monthly" value={<LabelDate input={month} inputFormat="M" outputFormat="MMMM" />} />
										<SpreadSheetKeyValuePair label="Year" value={<LabelDate input={year} inputFormat="Y" outputFormat="YYYY" />} />
										<SpreadSheetKeyValuePair label="Submitted At" value={<LabelDate input={createdAt} outputFormat={c.dateTimeFormat} />} />
										<>
											{updatedAt && (
												<>
													<SpreadSheetKeyValuePair
														label="Updated at"
														value={<LabelDate input={updatedAt} outputFormat={c.dateTimeFormat} />}
													/>
												</>
											)}
										</>
										<Flex mt={2}>
											<>
												{!dataGet?.getMonthlyClaimReportById?.isEditing && canAllowEditing && (
													<Button
														onMouseDown={onOpen}
														isLoading={false}
														isDisabled={false}
														onClick={() => null}
														leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
														mr={2}
													>
														{t('Allow Editing')}
													</Button>
												)}
											</>
											<Flex>
												<ExcelGeneral month={month} year={year} numColumns={10} claimItem={claimItem} />
											</Flex>
										</Flex>
									</Flex>
									{dataGet?.getMonthlyClaimReportById?.isEditing && (
										<Flex direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
											<SpreadSheetKeyValuePair
												label="Allow Editing"
												value={
													<LabelDate input={dataGet?.getMonthlyClaimReportById?.editingUpdatedAt} outputFormat={c.dateTimeFormat} />
												}
											/>
											<SpreadSheetKeyValuePair
												label="By"
												value={<UserDislayNameById id={dataGet?.getMonthlyClaimReportById?.editingUpdatedBy} />}
											/>
											<SpreadSheetKeyValuePair
												label="Due Date"
												value={<LabelDate input={dataGet?.getMonthlyClaimReportById?.editingDueDate} outputFormat={c.dateTimeFormat} />}
											/>
											<SpreadSheetKeyValuePair label="Description" value={dataGet?.getMonthlyClaimReportById?.editingDescription} />
											{canAllowEditing && (
												<>
													<Box mt={2}>
														<Button
															onMouseDown={onOpen}
															isLoading={false}
															isDisabled={false}
															onClick={() => null}
															leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
															colorScheme="red"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
															mr={2}
														>
															{t('Stop Allowing Editing')}
														</Button>
													</Box>
												</>
											)}
										</Flex>
									)}
								</SimpleGrid>
							</Flex>
							<Box pl={4} pr={4} pb={4} pt={4} overflow="clip">
								{/* mtpl <Tabs>
									<TabList borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.200">
										<Tab>
											<Text>{t('Claim')}</Text>
										</Tab>
										{object && (
											<Tab>
												<Text>{t('MTPL')}</Text>
											</Tab>
										)}
									</TabList>
									<TabPanels>
										<TabPanel pl={0} pr={0} pb={0}> */}
								<SpreadSheetTable claimProducts={claimItem?.claimProducts} />
								{/* mtpl </TabPanel>
										{object && (
											<TabPanel pl={0} pr={0} pb={0}>
												<SpreadSheetTableMtpl
													readOnlys={[0, 1, 6, 11, 16]}
													labels={object?.labels}
													provinces={object?.provinces}
													cells={object?.cells}
													onChange={(cells) =>
														setObject({
															...object,
															cells: object?.cells?.reduce((pre, cur, i) => {
																let localPre = pre;
																localPre.push(cur?.map((el) => ({ ...el, readOnly: true })));
																return localPre;
															}, []),
														})
													}
												/>
											</TabPanel>
										)}
									</TabPanels>
								</Tabs> */}
							</Box>
						</>
					)}
				</>
			)}
			{isOpen && (
				<EditMonthlyReportModal
					id={id}
					type="Claim"
					isEditable={dataGet?.getMonthlyClaimReportById?.isEditing}
					editableDueDate={dataGet?.getMonthlyClaimReportById?.editingDueDate}
					editableDescription={dataGet?.getMonthlyClaimReportById?.editingDescription}
					onClose={onClose}
					onCallback={loadDetail}
				/>
			)}
		</>
	);
});

export default Page;
