/* eslint-disable react-hooks/exhaustive-deps */
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Box,
	Flex,
	Text,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Image,
	InputGroup,
	Input,
	Button,
	TableContainer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ReactPaginate from 'react-paginate';
import LoadingTable from '../../../components/Table/Loading';
import Placeholder from '../../../assets/images/logo-placeholder.png';
import c from '../../../constant';

const Q_COMPANIES = gql`
	query get($pagerInput: PagerInput!, $companyType: String, $companyName: String) {
		getEwsReportSupportedCompanies(pagerInput: $pagerInput, companyType: $companyType, companyName: $companyName) {
			companies {
				id
				name
				nameKhmer
				logo
				url
				type
				latestUpdateBranchAt
			}
			totalPages
			totalRows
		}
	}
`;

export default function EwsCompanyModal({ companyType = null, isOpen = false, onClose = () => null, onCallback = () => null }) {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [companyName, setCompanyName] = useState('');
	const [fetchData, { data, loading }] = useLazyQuery(Q_COMPANIES);

	useEffect(load, [companyType, page]);

	function load() {
		const limit = 5;
		const offset = page ? (page - 1) * limit : 0;
		const pagerInput = { limit, offset };
		const variables = { pagerInput, companyType, companyName };
		fetchData({ variables });
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="3xl">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Company List')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<InputGroup size="md">
							<Input
								onKeyDown={(e) => {
									if (e?.key === 'Enter') {
										setPage(1);
										load();
									}
								}}
								mr={2}
								w="100%"
								pr="4.5rem"
								placeholder={t('Name')}
								autoFocus
								value={companyName}
								onChange={(e) => setCompanyName(e.target.value)}
							/>
							<Button
								borderRadius={c.borderRadius}
								size="md"
								onClick={() => {
									setPage(1);
									load();
								}}
							>
								{t('Search')}
							</Button>
						</InputGroup>
						<Box p={4}>
							<TableContainer>
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Logo')}</Th>
											<Th>{t('Khmer Name')}</Th>
											<Th isNumeric>{t('English Name')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{loading && <LoadingTable column={3} />}
										{data?.getEwsReportSupportedCompanies?.companies.length <= 0 && (
											<Tr>
												<Td colSpan={3}>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{data?.getEwsReportSupportedCompanies?.companies?.map((e, i) => {
											return (
												<Tr
													_hover={{ backgroundColor: 'gray.200' }}
													cursor="pointer"
													key={`company-row-${i}`}
													onClick={() => {
														onCallback(e);
														onClose();
													}}
												>
													<Td pt="0" pb="0">
														<Image
															src={e?.logo ? c.baseFilePath + e?.logo : ''}
															w="auto"
															minW="40px"
															minH="40px"
															h="40px"
															fallbackSrc={Placeholder}
														/>
													</Td>
													<Td>{e?.nameKhmer}</Td>
													<Td isNumeric>{e?.name}</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Logo')}</Th>
											<Th>{t('Khmer Name')}</Th>
											<Th isNumeric>{t('English Name')}</Th>
										</Tr>
									</Tfoot>
								</Table>
							</TableContainer>
							{data?.getEwsReportSupportedCompanies?.totalPages > 1 && (
								<Flex justifyContent="flex-end" w="100%" mt="4" mb={4}>
									<ReactPaginate
										previousLabel={<ChevronLeftIcon fontSize="20px" />}
										nextLabel={<ChevronRightIcon fontSize="20px" />}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={data?.getEwsReportSupportedCompanies?.totalPages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={3}
										onPageChange={({ selected }) => setPage((selected < 0 ? 0 : selected) + 1)}
										containerClassName={'pagination'}
										activeClassName={'active'}
										initialPage={(page - 1) | 0}
									/>
								</Flex>
							)}
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
