// const formatEWS = (value) => {
// 	if (value) {
// 		return value?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
// 	}
// 	return '0';
// };

const formatEWS = (value) => {
	if (value) {
		return value?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	} else return null;
};

export { formatEWS };
