import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { formatCurrency, formatPercentage } from '../../../utils/format';
import { useTranslation } from 'react-i18next';
import { toFixedTrunc } from '../../../utils/fixed-trunc';
import LabelDetail from '../../../components/LabelDetail';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import c from '../../../constant';

export default function ContributionSale({ contribution = null }) {
	const { t } = useTranslation();

	return (
		<>
			<Flex flex={1} border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
				<Box width="100%">
					<Flex justifyContent="flex-start" alignItems="center">
						<Text fontWeight="bold" fontSize="lg" color="gray.600">
							{t('Contribution')}
						</Text>
					</Flex>
					<SimpleGrid columns={[1, null, 2]} spacingX={8} mt={2}>
						<LabelDetail
							mb={1}
							mt={0}
							label="Gross Premium"
							labelColor="gray.600"
							childDetail={
								<Text fontWeight="bold" fontSize="lg" color="gray.700">
									{formatCurrency(toFixedTrunc(contribution?.grossPremium, 3))}
								</Text>
							}
						/>
						<LabelDetail
							mb={1}
							mt={0}
							label="Rate"
							labelColor="gray.600"
							childDetail={
								<Text fontWeight="bold" fontSize="lg" color="gray.700">
									{formatPercentage(contribution?.rate)}
								</Text>
							}
						/>
						<LabelDetail
							mb={1}
							mt={0}
							label="Monthly Contribution"
							labelColor="gray.600"
							childDetail={
								<Text fontWeight="bold" fontSize="lg" color="gray.700">
									{formatCurrency(toFixedTrunc((contribution?.grossPremium * contribution?.rate) / 100, 3))}
								</Text>
							}
						/>
						<LabelDetail
							mb={1}
							mt={0}
							label="Payment Invoice"
							labelColor="gray.600"
							childDetail={
								<>
									{contribution?.attachmentFile?.url ? (
										<DownloadAndViewButton
											url={contribution?.attachmentFile?.url}
											size={contribution?.attachmentFile?.size}
											marginLeft={0}
										/>
									) : (
										'N/A'
									)}
								</>
							}
						/>
					</SimpleGrid>
				</Box>
			</Flex>
		</>
	);
}
