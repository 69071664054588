import moment from 'moment';
import 'moment/locale/en-gb';

export const capitalizeFirstLetter = (string) => {
	return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const checkLogin = () => {
	let accessToken = sessionStorage.getItem('accessToken');
	return accessToken;
};

export const getInitSelectSearch = ({ value, options }) => {
	const filter = options.filter(function (el) {
		return el.value === value;
	});
	return filter.length > 0 ? filter[0] : null;
};

export const formatAgentCode = (code) => {
	return code.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1-');
};

export const dateRangeStringToDateRange = (string) => {
	if (string) {
		const object = JSON.parse(string);
		if (object?.from && object?.to) {
			let from = { day: object?.from?.day, month: object?.from?.month - 1, year: object?.from?.year };
			let to = { day: object?.to?.day, month: object?.to?.month - 1, year: object?.to?.year };
			from = moment(from).startOf('day').toDate();
			to = moment(to).endOf('day').toDate();
			return { from, to };
		}
	}
	return null;
};

export const dateRangeToQueryString = (object) => {
	if (object) {
		const dateFrom = moment(object?.from);
		const dateTo = moment(object?.to);
		const from = { day: parseInt(dateFrom?.format('DD')), month: parseInt(dateFrom?.format('MM')), year: parseInt(dateFrom?.format('YYYY')) };
		const to = { day: parseInt(dateTo?.format('DD')), month: parseInt(dateTo?.format('MM')), year: parseInt(dateTo?.format('YYYY')) };
		return JSON.stringify({ from, to });
	}
	return null;
};
