import React, { useState, useRef, useEffect } from 'react';
import { Flex, Tooltip, Text, Box, Textarea, Button, useToast } from '@chakra-ui/react';
import { faFile, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import c from '../../constant';

const ADD_COMMENT_MUTATION = gql`
	mutation add($attachmentFileSize: Float, $attachmentFile: Upload, $commentOn: String!, $description: String, $type: String!, $isSubComment: Boolean) {
		addComment(
			description: $description
			attachmentFile: $attachmentFile
			attachmentFileSize: $attachmentFileSize
			commentOn: $commentOn
			type: $type
			isSubComment: $isSubComment
		) {
			id
			description
			attachmentFile
			createdAt
			commentOn
		}
	}
`;

export default function WriteComment({ id, type, callback, isSubComment = false }) {
	const { t } = useTranslation();
	const toast = useToast();
	const fileInput = useRef(null);
	const [description, setDescription] = useState('');
	const [attachmentFile, setAttachmentFile] = useState(null);

	const [addComment, { loading, error, data }] = useMutation(ADD_COMMENT_MUTATION);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	useEffect(() => {
		if (data?.addComment?.id) {
			setDescription('');
			setAttachmentFile(null);
			callback();
		}
	}, [data]);

	const handleClickFile = () => {
		fileInput.current.click();
	};

	const handleFileChange = (event) => {
		var fileInput = false;
		if (event.target.files[0]) {
			fileInput = true;
		}
		if (fileInput) {
			const file = event.target.files[0];
			if (file.size > c.maxFileSize) {
				toast({
					title: t('File size over limit'),
					status: 'warning',
					isClosable: true,
					position: 'top-right',
				});
			} else {
				setAttachmentFile(event.target.files[0]);
			}
		}
	};

	function submitComment() {
		if (description || attachmentFile) {
			const variables = {
				description,
				attachmentFile: attachmentFile,
				attachmentFileSize: attachmentFile?.size,
				commentOn: id,
				type: type,
				isSubComment,
			};
			addComment({
				variables,
			});
		}
	}

	return (
		<>
			<Flex direction="column" p="4" className="hidden-print">
				<Box w="100%" h="1px" bg="gray.200" mb="4" />
				<Text mb={2} fontWeight="500">
					{t('Write')}
				</Text>
				<Textarea
					disabled={loading}
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					w="100%"
					placeholder={t('Leave a comment')}
					noOfLines={4}
				/>
				<Flex justifyContent="space-between" alignItems="center" mt="2">
					<Flex>
						<Tooltip label={t('Maximum file size 100MB')}>
							<Flex
								cursor="pointer"
								alignItems="center"
								m="2"
								onClick={() => {
									if (!loading) {
										handleClickFile();
									}
								}}
							>
								<Text>{t('Attachment')}</Text>
								<FontAwesomeIcon icon={faFile} color="#303030" style={{ fontSize: 18, marginLeft: 4 }} />
								<Text bg="gray.200" pl="2" pr="2" ml="2">
									{attachmentFile?.name}
								</Text>
							</Flex>
						</Tooltip>
						<input
							style={{ display: 'none' }}
							type="file"
							onChange={(e) => handleFileChange(e)}
							ref={fileInput}
							accept="application/pdf,.xlsx,.xls,image/*,.doc, .docx,.txt,.zip,.rar,.7zip"
						/>
					</Flex>
					<Flex justifyContent="flex-end">
						<Button
							color="#3498DA"
							rightIcon={
								<FontAwesomeIcon
									icon={faPaperPlane}
									color="#303030"
									style={{
										fontSize: 14,
										marginLeft: 4,
										marginBottom: 4,
										color: '#3498DA',
									}}
								/>
							}
							isLoading={loading}
							onClick={() => submitComment()}
						>
							{t('Send')}
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
}
