const mappingKhmerNumber = (input) => {
	const mapping = {
		0: '០',
		1: '១',
		2: '២',
		3: '៣',
		4: '៤',
		5: '៥',
		6: '៦',
		7: '៧',
		8: '៨',
		9: '៩',
	};
	const numbers = input.toString().split('');
	const n = numbers.map((e) => mapping[e]);
	return n.join('');
};

const isNumeric = (value) => {
	const numberRegex = /^[-+]?[0-9]*(\.[0-9]+)?([eE][+-]?\d+)?$/;
	return numberRegex?.test(value?.toString() || null);
};

const validateSpreadsheet = (spreadsheetData) => {
	let isValid = true;
	spreadsheetData?.forEach((row) => {
		row?.forEach((column) => {
			if (!isNumeric(column?.value)) {
				isValid = false;
			}
		});
	});
	return isValid;
};

const validateSpreadsheets = (spreadsheets) => {
	let isValid = true;
	spreadsheets?.forEach((sheet) => {
		sheet?.forEach((row) => {
			row?.forEach((col) => {
				if (!isNumeric(col?.value)) {
					isValid = false;
				}
			});
		});
	});
	return isValid;
};

function checkSelectedActiveRow(active, index, t) {
	if (active?.row === index && t === active?.t) {
		return true;
	}
	return false;
}

function checkSelectedActiveColumn(active, index) {
	if (active?.column === index) {
		return true;
	}
	return false;
}

function cleanSpreadsheet(numRow, numColumn) {
	return [
		...Array(numRow)
			.keys()
			.reduce((p, c) => {
				let arr = p;
				arr.push([
					...Array(numColumn)
						.keys()
						.reduce((p, c) => {
							let arr = p;
							arr.push({ value: '' });
							return arr;
						}, []),
				]);
				return arr;
			}, []),
	];
}

const saleReportTypes = [
	{ labelKh: 'បុគ្គល', labelEn: 'Individual', value: 'Individual' },
	{ labelKh: 'ក្រុម', labelEn: 'Group', value: 'Group' },
	{ labelKh: 'ការវិនិយោគ', labelEn: 'Investment', value: 'Investment' },
	{ labelKh: 'កាតព្វកិច្ច', labelEn: 'MTPL', value: 'MTPL' },
];

const mappingCompanyType = { GENERAL_INSURANCE: 'General', LIFE_INSURANCE: 'Life', MICRO_INSURANCE: 'Micro' };

const mappingTypeOfReport = { GENERAL_INSURANCE: 'General', LIFE_INSURANCE: 'Life', MICRO_INSURANCE: 'Micro' };

export {
	mappingKhmerNumber,
	validateSpreadsheet,
	validateSpreadsheets,
	isNumeric,
	checkSelectedActiveRow,
	checkSelectedActiveColumn,
	cleanSpreadsheet,
	saleReportTypes,
	mappingCompanyType,
	mappingTypeOfReport,
};
