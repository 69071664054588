/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Container, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PrintLayout from '../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import GeneralMonthlySaleDetail from '../../../components/MarketDevelopment/MonthlySale/General/page';
import LifeMonthlySaleDetail from '../../../components/MarketDevelopment/MonthlySale/Life/page';
import MicroMonthlySaleDetail from '../../../components/MarketDevelopment/MonthlySale/Micro/page';
import composeQueries from '../../../utils/composeQueries';
import c from '../../../constant';

function MonthlySaleReportDetail({ modalId = null, modalReportType = null }) {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') || modalId;
	const reportType = urlParams.get('reportType') || modalReportType;
	const period = urlParams.get('period') || null;
	const reportId = urlParams.get('reportId') || null;
	const companyType = urlParams.get('companyType') || null;
	const companyId = urlParams.get('companyId') || null;
	const { t } = useTranslation();

	function SpreadSheet() {
		return (
			<>
				{reportType === 'General' && (
					<>
						<GeneralMonthlySaleDetail id={id} />
					</>
				)}
				{reportType === 'Life' && (
					<>
						<LifeMonthlySaleDetail id={id} />
					</>
				)}
				{reportType === 'Micro' && (
					<>
						<MicroMonthlySaleDetail id={id} />
					</>
				)}
			</>
		);
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					{!modalId && (
						<BreadcrumbComponent
							list={[
								{
									name: 'Company Report',
									path: '/company-submission-report',
								},
								{
									name: period,
									path: composeQueries('/market-development-data?period=' + period + '&reportId=' + reportId, { companyType, companyId }),
								},
								{
									name: 'Sale Report',
									path: composeQueries(
										'/market-development-data/monthly-sale-report?period=' + period + '&reportId=' + reportId + '&page=1',
										{ companyType, companyId }
									),
								},
								{
									name: 'Detail',
									path: '#',
								},
							]}
						/>
					)}
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={4}
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Text fontSize="x-large" fontWeight="bold">
									{t('Insurance Sales Report')}
								</Text>
							</Flex>
							<SpreadSheet />
						</Box>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default MonthlySaleReportDetail;
